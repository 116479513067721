import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import '@reach/skip-nav/styles.css';
import { Divider, Stack, Text, useDisclosure } from '@chakra-ui/react';
import { TermsModal, coreSharedMessages } from 'core';
import { useWindowBreakpoints } from 'design-system/hooks';
import { useTerms } from 'lib/account';
import LayoutBoundary from './LayoutBoundary';

type FooterLinkType =
    | 'legal-notices'
    | 'general-conditions-of-use'
    | 'privacy-policy';

const footerLinks: FooterLinkType[] = [
    'legal-notices',
    'general-conditions-of-use',
    'privacy-policy',
];

type FooterLinksProps = {
    hasBackground?: boolean;
};

export default function Footer({ hasBackground = false }: FooterLinksProps) {
    const { isMobile } = useWindowBreakpoints();
    const { formatMessage } = useIntl();
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [selectedTerms, setSelectedTerms] = React.useState<FooterLinkType>();
    const { data, status } = useTerms(selectedTerms, !!selectedTerms);

    const _renderTermLink = (type: FooterLinkType): JSX.Element => {
        return (
            <Text
                key={type}
                w="fit-content"
                textDecoration="underline"
                textAlign="center"
                cursor="pointer"
                fontSize="xs"
                fontWeight="bold"
                onClick={() => {
                    setSelectedTerms(type);
                    onOpen();
                }}>
                <FormattedMessage {...coreSharedMessages[type]} />
            </Text>
        );
    };

    return (
        <>
            {isOpen && (
                <TermsModal
                    {...{
                        isOpen,
                        status,
                        onClose,
                        title: formatMessage(coreSharedMessages[selectedTerms]),
                        content: data,
                        LayoutBoundary,
                    }}
                />
            )}
            <Stack
                color={isMobile || !hasBackground ? 'grey.800' : 'white'}
                fontSize="xs"
                fontWeight="bold"
                textAlign="center"
                textDecoration="underline"
                alignItems="center"
                justifyContent="center"
                spacing={isMobile ? 4 : 2}
                px={isMobile && 5}
                direction={isMobile ? 'column' : 'row'}
                divider={
                    <Divider
                        bg="strokes.medium"
                        h={!isMobile && 4}
                        w={!isMobile ? 0 : undefined}
                    />
                }>
                {footerLinks.map((type) => _renderTermLink(type))}
            </Stack>
        </>
    );
}
