import { Box, Text } from '@chakra-ui/react';
import { FormattedMessage } from 'react-intl';
import { coreErrors } from 'core';
import { useWindowBreakpoints } from 'design-system/hooks';

export function InvalidData() {
    const { isMobile } = useWindowBreakpoints();

    return (
        <Box
            border="2px solid"
            borderColor="grey.200"
            rounded="md"
            bg="white"
            p="4"
            width="full">
            <Text
                fontFamily="mono"
                fontSize="sm"
                color="primary.main"
                width={isMobile ? '100%' : ''}>
                <FormattedMessage {...coreErrors.errorInvalidProviderData} />
            </Text>
        </Box>
    );
}
