import { useInfiniteQuery } from '@tanstack/react-query';
import axios, { AxiosRequestConfig } from 'axios';
import {
    APIError,
    groupInfiniteQuery,
    useSafeIntl,
    DashboardContext,
    SelectedFilter,
    getFiltersQuery,
} from 'core';
import React from 'react';
import { getNextPageParam } from 'core/lib/shared/helpers/getNextPageParam';
import { ListTableRow } from 'components/ListTable/ListTable';
import { defineMessages } from 'react-intl';
import {
    CompanyIcon,
    ExternalLinkIcon,
    FileFileIcon,
    PlusIcon,
} from 'design-system/icons';
import { Company } from 'lib/companies';
import {
    ContractDetailsModal,
    DeleteContract,
    DeleteDocument,
} from 'components/contracts';
import { sharedMessages } from 'lib/shared';

type FetchContractsApi = Api<Company[], null, { pagination: Pagination }>;

const contractsPath =
    '/api/proxy/employer-beneficiary-designation/admin/companies';

const listMessages = {
    ...defineMessages({
        contract: {
            id: 'components.management-contracts-number',
            defaultMessage:
                '{nbContract, plural, =0 {Aucun contrat} one {# contrat} other {# contrats}}',
        },
        addContract: {
            id: 'buttons.add-a-contract',
            defaultMessage: 'Ajouter un contrat',
        },
    }),
};
async function fetchContracts(
    config: AxiosRequestConfig,
    safeFormatMessage: Function,
    mergedFilters: SelectedFilter[]
) {
    try {
        const mergedFiltersQuery = getFiltersQuery(mergedFilters);

        const { data: response } = await axios.get(
            `${contractsPath}${mergedFiltersQuery && `?${mergedFiltersQuery}`}`,
            {
                ...config,
            }
        );

        const { data, meta }: FetchContractsApi = response;

        return {
            pagination: meta?.pagination,
            contracts: data?.map((company) =>
                company?.contracts?.map((contract) => [
                    {
                        type: 'contractNameDetails',
                        data: {
                            status: {
                                deactivationDate: contract.deactivationDate,
                            },
                            name: {
                                labelOne: contract.number,
                                labelTwo: contract.name,
                                Icon: FileFileIcon,
                            },
                        },
                    },
                    {
                        type: 'redirectTag',
                        data: {
                            formattedText: contract.clause.name,
                            bg: 'blue.50',
                            color: 'blue.700',
                            redirection: `/clauses`,
                            data: contract.clause.identifier,
                        },
                    },
                    {
                        type: 'iconTag',
                        data: contract.documentDisplayName && {
                            formattedText: contract.documentDisplayName,
                            link: contract && contract.identifier,
                            bg: 'grey.50',
                            color: 'grey.800',
                            LeftIcon: FileFileIcon,
                            RightIcon: ExternalLinkIcon,
                        },
                    },
                    {
                        type: 'copyLink',
                        data: contract.employeePortal && {
                            label: safeFormatMessage(sharedMessages.copyUrl),
                            value: contract.employeePortal.url,
                        },
                    },
                    {
                        type: 'copyCode',
                        data: contract.employeePortal && {
                            label: safeFormatMessage(sharedMessages.code, {
                                code: contract.employeePortal.code,
                            }),
                            value: contract.employeePortal.code,
                        },
                    },
                    {
                        type: 'relatedActions',
                        data: {
                            items: [
                                {
                                    name: 'modifyContract',
                                    component: ContractDetailsModal,
                                    data: {
                                        identifier: contract.identifier,
                                        clauseId: contract.clause.identifier,
                                        isEdit: true,
                                        successToast: true,
                                    },
                                },
                                {
                                    name: 'deleteDocument',
                                    component: DeleteDocument,
                                    disabled: !contract.documentDisplayName,
                                    data: {
                                        identifier: contract.identifier,
                                    },
                                },
                                {
                                    name: 'deleteContract',
                                    component: DeleteContract,
                                    data: {
                                        identifier: contract.identifier,
                                        contractName: contract.name,
                                        contractNumber: contract.number,
                                    },
                                },
                            ],
                        },
                    },
                ])
            ),
            companies: data?.map((company, i) => [
                {
                    type: 'name',
                    data: {
                        name: {
                            labelOne: company.name,
                            labelTwo: null,
                            Icon: CompanyIcon,
                            iconHasBg: true,
                            isFirstColumn: true,
                        },
                        licenseNumber: company.licenseNumber,
                        groupName: company.group,
                    },
                },
                {
                    type: 'contractActions',
                    data: {
                        tag: {
                            bg: company.contracts.length
                                ? 'blue.50'
                                : 'grey.100',
                            color: company.contracts.length
                                ? 'blue.700'
                                : 'grey.800',
                            formattedText: safeFormatMessage(
                                listMessages.contract,
                                {
                                    nbContract: company.contracts.length,
                                }
                            ),
                        },
                        actionTag: {
                            bg: 'grey.50',
                            formattedText: safeFormatMessage(
                                listMessages.addContract
                            ),
                            Icon: PlusIcon,
                            companyId: company.identifier,
                        },
                        toggleButton: {
                            idx: i,
                        },
                        isInactive: !company.contracts.length,
                    },
                },
            ]),
        };
    } catch (err) {
        throw { code: err.response?.status };
    }
}

function useContracts() {
    const { safeFormatMessage } = useSafeIntl();
    const { selectedFilters, currentPage } = React.useContext(DashboardContext);
    const start = (currentPage - 1) * 10 + 1;
    const arrayUniqueId = btoa(JSON.stringify(selectedFilters));

    const { data, ...queryProps } = useInfiniteQuery<any, APIError>({
        initialPageParam: start,
        queryKey: [contractsPath, 'GET', start, arrayUniqueId],
        queryFn: ({ pageParam = start }) =>
            fetchContracts(
                {
                    params: {
                        start: pageParam,
                        count: 10,
                    },
                },
                safeFormatMessage,
                selectedFilters
            ),
        getNextPageParam,
    });
    const companies = groupInfiniteQuery<ListTableRow>('companies', data);
    const contracts = groupInfiniteQuery<ListTableRow>('contracts', data);
    return {
        companies,
        contracts,
        ...data,
        ...queryProps,
    };
}

export { contractsPath, fetchContracts, useContracts };
