import React from 'react';
import { uniqueId } from 'lodash';
import { useRouter } from 'next/router';
import { FormattedMessage, MessageDescriptor } from 'react-intl';
import { Tab, Tabs, TabList, Container } from '@chakra-ui/react';
import { UrlObject } from 'url';
import { useRedirect } from 'core';

export type HeaderTabsProps = {
    tabs: {
        href: UrlObject | string;
        title: MessageDescriptor | string;
    }[];
    colorScheme?: 'black' | 'whiteAlpha';
    defaultIndex?: number;
    size?: string;
};

export function HeaderTabs({
    tabs,
    colorScheme = 'black',
    defaultIndex,
    size,
}: HeaderTabsProps) {
    const { redirect } = useRedirect();
    const router = useRouter();
    const selectedTab = tabs.find((tab) => tab.href === router.pathname);
    const routeDefaultIndex = selectedTab
        ? tabs.indexOf(selectedTab)
        : undefined;

    React.useEffect(() => {
        tabs.forEach((tab) =>
            router.prefetch(
                typeof tab.href === 'string' ? tab.href : tab.href.pathname
            )
        );
    }, [router, tabs]);

    return (
        <Container
            size={size}
            overflow="auto hidden"
            pb="2px" // This show the border under the tab
            sx={{
                '&::-webkit-scrollbar': {
                    display: 'none',
                },
            }}>
            <Tabs
                as="nav"
                colorScheme={colorScheme}
                defaultIndex={defaultIndex || routeDefaultIndex}
                isManual
                onChange={(idx) => {
                    redirect(tabs[idx].href);
                }}>
                <TabList zIndex={10}>
                    {tabs.map((tab) =>
                        typeof tab.title === 'string' ? (
                            <Tab key={uniqueId()} flexShrink={0}>
                                {tab.title}
                            </Tab>
                        ) : (
                            <Tab key={tab.title.id} flexShrink={0}>
                                <FormattedMessage {...tab.title} />
                            </Tab>
                        )
                    )}
                </TabList>
            </Tabs>
        </Container>
    );
}
