import React from 'react';
import { AppProps } from 'next/app';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { MatomoProvider, createInstance } from '@datapunt/matomo-tracker-react';

import { ThemeProvider } from 'themes';
import { siteConfig } from 'lib/site';
import { CACHE_TIME } from 'lib/shared';
import { FontFaces, ErrorInterceptor, LayoutBoundary } from 'components';
import { UserProvider, LanguageProvider } from 'lib/user';
import {
    LoadingProgressBar,
    CombineProviders,
    combineFCProps,
    Matomo,
    UtilsProvider,
    PermissionsProvider,
    LinkIntl,
    DashboardProvider,
} from 'core';
import routes from 'routes';
import { useHandleSubmitResponse } from 'lib/forms';
import { DesignSystemProvider } from 'design-system/lib';
import * as Sentry from '@sentry/nextjs';
import getConfig from 'next/config';

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
            retry: false,
            gcTime: CACHE_TIME,
            staleTime: CACHE_TIME,
        },
    },
});

const matomoInstance = createInstance({
    urlBase: process.env.NEXT_PUBLIC_FRONT_URL,
    siteId: parseInt(process.env.NEXT_PUBLIC_MATOMO_ID),
    trackerUrl: 'https://matomo.optis.xyz/matomo.php',
    srcUrl: 'https://matomo.optis.xyz/matomo.js',
    configurations: {
        disableCookies: true,
    },
});

export default function App({ Component, pageProps, router }: AppProps) {
    const { publicRuntimeConfig } = getConfig();
    Sentry.setTag(
        'build.datetime',
        new Date(publicRuntimeConfig.buildDate).toLocaleString()
    );

    return (
        <CombineProviders
            providers={[
                combineFCProps(MatomoProvider, { value: matomoInstance }),
                combineFCProps(QueryClientProvider, {
                    client: queryClient,
                }),
                combineFCProps(LanguageProvider),
                combineFCProps(ThemeProvider, {
                    client: siteConfig.theme.partner,
                    variant: siteConfig.theme.variant,
                }),
                combineFCProps(PermissionsProvider),
                combineFCProps(UserProvider),
                combineFCProps(UtilsProvider, {
                    routes,
                    siteConfig,
                    hooks: { useHandleSubmitResponse },
                    layouts: {
                        LayoutBoundary,
                    },
                }),
                combineFCProps(DesignSystemProvider, {
                    LinkComponent: LinkIntl,
                    themeConfig: siteConfig.theme,
                }),
                combineFCProps(DashboardProvider),
            ]}>
            <FontFaces />
            <LoadingProgressBar
                height="5"
                bgColor={
                    router.pathname.includes('login')
                        ? 'white'
                        : 'tertiary.main'
                }
            />
            <ErrorInterceptor />
            <Matomo>
                <Component {...pageProps} />
            </Matomo>
            <ReactQueryDevtools
                initialIsOpen={process.env.NODE_ENV === 'development'}
            />
        </CombineProviders>
    );
}
