import {
    ViolationMessage,
    useFormValidation,
    FormValues,
    flattenViolations,
} from 'core';
import { FormModal } from 'core/components/modals/FormModal';
import { CompanyDetails } from 'lib/companies';
import { Environment } from 'lib/environments';
import { themeFormPath, useThemeForm } from 'lib/shared/api/useThemeForm';
import { Group } from 'lib/groups';
import { getResourceType } from 'lib/shared/helpers';
import React, { useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';

type EditThemeProps = {
    isOpen: boolean;
    resource: Group | CompanyDetails | Environment;
    onClose: () => void;
    onSuccess: () => void;
};

export default function EditTheme({
    isOpen,
    resource,
    onClose,
    onSuccess,
}: EditThemeProps) {
    const { status, data: form, error, isFetching } = useThemeForm(resource);
    const queryClient = useQueryClient();

    const [errors, setErrors] = useState<ViolationMessage[]>([]);

    const { onSubmit, methods, isSubmitting } = useFormValidation(
        'pe-admin',
        resource?.identifier
            ? form?.iri.replace('{identifier}', resource?.identifier)
            : form?.iri,
        form?.widgets
    );
    const [submitErrorCode, setSubmitErrorCode] = React.useState<number>(null);

    const handleOnSubmit = async (data: FormValues) => {
        const result = await onSubmit(data);
        const { identifier } = resource;

        if (result) {
            if (result.status >= 400 && ![412].includes(result.status))
                setSubmitErrorCode(result.status);
            else setSubmitErrorCode(null);

            switch (result.status) {
                case 200:
                    const convertedPath = themeFormPath.replace(
                        '[resource]',
                        getResourceType(resource)
                    );
                    queryClient.invalidateQueries({
                        queryKey: [
                            convertedPath.replace('/theme', ''),
                            identifier,
                        ],
                    });
                    queryClient.invalidateQueries({
                        queryKey: [convertedPath, identifier, 'OPTIONS'],
                        refetchType: 'active',
                    });
                    onClose();
                    onSuccess();
                    break;
                default:
                    const resultData = result?.data?.data || result?.data;
                    if (resultData?.violations) {
                        const violations = flattenViolations(
                            resultData?.violations
                        );
                        setErrors(violations);
                    }
                    break;
            }
        }
    };

    return (
        <FormModal
            isOpen={isOpen}
            onClose={onClose}
            form={form}
            errors={errors}
            onSubmit={handleOnSubmit}
            methods={methods}
            isSubmitting={isSubmitting}
            formStatus={isFetching ? 'pending' : status}
            isFetching={isFetching}
            formError={error}
            submitErrorCode={submitErrorCode}
        />
    );
}
