import { defineMessage, useIntl } from 'react-intl';
import {
    flattenViolations,
    FormObject,
    FormValues,
    useFormValidation,
    ViolationMessage,
    FormTypesEnum,
    autoSelectContractClause,
} from 'core';
import { useToast } from 'design-system/components';
import React from 'react';
import { FormModal } from 'core/components/modals/FormModal';
import {
    addContractFormPath,
    contractsPath,
    editContractFormPath,
    useContractForm,
} from '../../lib/contracts';
import { useQueryClient } from '@tanstack/react-query';
import { clausePath, clausesPath } from 'lib/clauses';

const intlMessages = {
    existingGroupErrorToast: defineMessage({
        id: 'errors.pe-admin.contract-creation.existing-contract',
        defaultMessage: 'Un contrat avec le même numéro existe déjà.',
    }),
};

type ContractDetailsModalProps = {
    isOpen: boolean;
    identifier: string;
    onClose: () => void;
    onSuccess: (contractName?: string) => void;
    isEdit?: boolean;
    clauseId?: string;
};

const ACCESS_DENIED_ERROR = '7f58e896-be5a-11ed-afa1-0242ac120002';
const EXISTING_CONTRACT_ERROR = '84b54335-b005-4d7c-a69d-31d6cfe495d2';

export default function ContractDetailsModal({
    isOpen,
    identifier,
    onClose,
    onSuccess,
    isEdit = false,
    clauseId,
}: ContractDetailsModalProps) {
    const { formatMessage } = useIntl();
    const queryClient = useQueryClient();

    const {
        status,
        data: form,
        error,
        isFetching,
    } = useContractForm(
        identifier,
        isEdit ? FormTypesEnum.EDIT : FormTypesEnum.ADD
    );

    const [errors, setErrors] = React.useState<ViolationMessage[]>([]);
    const [formData, setFormData] = React.useState<FormObject>();

    const { onSubmit, methods, isSubmitting } = useFormValidation(
        'employer-beneficiary-designation',
        form?.iri.replace('{identifier}', identifier),
        form?.widgets
    );
    const [submitErrorCode, setSubmitErrorCode] = React.useState<number>(null);

    const existingContractErrorToast = useToast({
        status: 'error',
        description: formatMessage(intlMessages.existingGroupErrorToast), // @TODO: add the right trans key
    });

    const accessDeniedErrorToast = useToast({
        status: 'error',
        description: formatMessage(intlMessages.existingGroupErrorToast), // @TODO: add the right trans key
    });

    React.useEffect(() => {
        if (form) {
            setFormData(
                clauseId ? autoSelectContractClause(form, clauseId) : form
            );
        }
    }, [form, clauseId]);

    const handleOnSubmit = async (data: FormValues) => {
        const result = await onSubmit(data);

        if (result) {
            if (
                result.status >= 400 &&
                result.status !== 412 &&
                result.status !== 403
            ) {
                setSubmitErrorCode(result.status);
            } else {
                setSubmitErrorCode(null);
            }

            switch (result.status) {
                case 200:
                    queryClient.invalidateQueries({
                        queryKey: [
                            isEdit ? editContractFormPath : addContractFormPath,
                            identifier,
                            'OPTIONS',
                        ],
                    });
                    queryClient.invalidateQueries({
                        queryKey: [contractsPath, 'GET'],
                    });
                    if (clauseId) {
                        queryClient.invalidateQueries({
                            queryKey: [clausePath, clauseId],
                        });
                        queryClient.invalidateQueries({
                            queryKey: [clausesPath, 'GET'],
                        });
                    }
                    onClose();
                    onSuccess(data?.contractNumber.value);
                    break;
                case 403:
                    const errorCode = result.data?.errors[0].code;
                    if (errorCode === EXISTING_CONTRACT_ERROR) {
                        existingContractErrorToast();
                    } else if (errorCode === ACCESS_DENIED_ERROR) {
                        accessDeniedErrorToast();
                    } else {
                        setSubmitErrorCode(result.status);
                    }
                default:
                    const resultData = result?.data?.data || result?.data;
                    if (resultData?.violations) {
                        const violations = flattenViolations(
                            resultData?.violations
                        );
                        setErrors(violations);
                    }
                    break;
            }
        }
    };

    return (
        <>
            <FormModal
                isOpen={isOpen}
                onClose={onClose}
                form={formData}
                errors={errors}
                onSubmit={handleOnSubmit}
                methods={methods}
                isSubmitting={isSubmitting}
                formStatus={status}
                isFetching={isFetching}
                formError={error}
                submitErrorCode={submitErrorCode}
            />
        </>
    );
}
