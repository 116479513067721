import { Environment } from '../../environments/types';
import { getResourceType } from 'lib/shared/helpers';
import { CompanyDetails } from '../../companies/types';
import { Group } from '../../groups/types';
import axios from 'axios';
import { useMutation } from '@tanstack/react-query';
import { APIError } from 'core';

const resetThemePath = '/api/proxy/pe-admin/[resource]/[id]/theme';

async function resetTheme(path: string, id: string) {
    try {
        const res = await axios.delete(path.replace('[id]', id));

        return res.data.data;
    } catch (error) {
        throw {
            code: error.response?.status,
        };
    }
}

export function useResetTheme(resource: Group | CompanyDetails | Environment) {
    const customizedPath = resetThemePath.replace(
        '[resource]',
        getResourceType(resource)
    );

    return useMutation<any, APIError, any>({
        mutationFn: (id: string) => resetTheme(customizedPath, id),
    });
}
