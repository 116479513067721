import React, { useContext } from 'react';
import { SkipNavLink } from '@reach/skip-nav';
import { FormattedMessage } from 'react-intl';
import '@reach/skip-nav/styles.css';
import { Box, Container, Flex, HStack, Stack, Text } from '@chakra-ui/react';
import {
    HeadTitle,
    coreSharedMessages,
    coreErrors,
    LoadingCard,
    ErrorPage,
    GlobalLayoutType,
    PermissionsContextType,
    managePermissionsForLabels,
    managePermissionsForURLs,
    Site,
    DashboardContext,
    usePermissionsContext,
} from 'core';
import { Breadcrumbs, BreadcrumbType } from 'design-system/components';
import { useWindowBreakpoints } from 'design-system/hooks';
import { useRouter } from 'next/router';
import { UserContext } from 'lib/user';
import LayoutBoundary from './LayoutBoundary';
import { Error } from 'core/components/Layouts/ErrorComponent';
import Footer from './Footer';
import { NavBar } from '.';
import { ErrorBoundary } from '@sentry/nextjs';
import { siteConfig } from 'lib/site';
import { MENU_WIDTH, sharedMessages } from 'lib/shared';
import CoreMenu from 'core/components/CoreMenu';

function getMaxContentWidth() {
    return `calc(100% - ${MENU_WIDTH})`;
}

type GlobalLayoutProps = GlobalLayoutType & {
    breadcrumbs?: BreadcrumbType[];
    NavbarComponent?: React.ComponentType;
    onRemove?: () => void;
};

export default function GlobalLayout({
    children,
    title,
    breadcrumbs = [],
    NavbarComponent = null,
    ...boxProps
}: GlobalLayoutProps) {
    const router = useRouter();
    const {
        isMobile,
        isSmallDevice,
        isExtraLargeDevice,
        isExtraLargeAndMoreDevice,
    } = useWindowBreakpoints();
    const userData = React.useContext(UserContext);
    const { permissions } =
        usePermissionsContext<PermissionsContextType<string[]>>();
    const { setSelectedFilters } = useContext(DashboardContext);

    const { menu } = siteConfig;
    const links = menu.links
        .filter((link) => link.show === 'always' || link.show === 'desktop')
        .map((entry) => {
            let managedEntry = null;
            if (Array.isArray(entry.label)) {
                const label = managePermissionsForLabels(
                    entry.label,
                    permissions
                );
                managedEntry = { ...entry, label };
            }
            if (Array.isArray(entry.to)) {
                const url = managePermissionsForURLs(
                    entry.to,
                    permissions,
                    '/home'
                );
                managedEntry = managedEntry
                    ? { ...managedEntry, to: url }
                    : { ...entry, to: url };
            }
            return managedEntry ? managedEntry : entry;
        }) as Site.MenuLink[];

    const allowedMenuEntries = links.filter(
        (link) =>
            !link.permissions?.length ||
            !!link.permissions.find((permission) =>
                permissions.includes(permission)
            )
    );

    return (
        <LayoutBoundary
            status={
                userData?.theme === undefined
                    ? 'pending'
                    : userData?.theme
                      ? 'success'
                      : 'error'
            }
            LoadingComponent={() => (
                <Stack h="100vh" justifyContent="center" alignItems="center">
                    <LoadingCard />
                    <Text>
                        <FormattedMessage {...coreSharedMessages.loading} />
                    </Text>
                </Stack>
            )}
            ErrorComponent={() => (
                <Container
                    size={isMobile ? 'full' : 'md'}
                    p={isMobile && '6'}
                    height="100vh"
                    margin="auto"
                    display="flex"
                    justifyContent="center"
                    alignItems="center">
                    <Error
                        message={coreErrors.unavailableContentTitle}
                        description={coreErrors.unavailableContentText}
                        errorButtons={[
                            {
                                text: coreSharedMessages.reloadPage,
                                onClick: () => router.reload(),
                            },
                        ]}
                    />
                </Container>
            )}
            p="0">
            <SkipNavLink contentId="content">
                <FormattedMessage
                    id="components.layouts.switch-content"
                    defaultMessage="Passer au contenu"
                    description="Texte de l'action pour passer au contenu principal"
                />
            </SkipNavLink>
            <HStack
                alignItems="stretch"
                spacing="0"
                minH="100vh"
                minW={!isSmallDevice ? getMaxContentWidth() : '100%'}>
                {title && <HeadTitle title={title} />}
                <Flex>
                    {!isSmallDevice && (
                        <CoreMenu
                            links={allowedMenuEntries}
                            width={MENU_WIDTH}
                            logoPath={menu.mainLogo.path}
                            resetFilters={setSelectedFilters}
                            intlMessages={sharedMessages}
                        />
                    )}
                </Flex>
                <Flex
                    bg="grey.50"
                    py={!isSmallDevice ? '8' : '4'}
                    pt={!isSmallDevice ? '20' : '8'}
                    px={
                        (isExtraLargeDevice && '32') ||
                        (isExtraLargeAndMoreDevice && '64') ||
                        (!isSmallDevice && '16')
                    }
                    w={!isSmallDevice ? getMaxContentWidth() : '100%'}>
                    <Stack gap="4" flex="1" maxW="100%">
                        <Stack
                            px={isSmallDevice && '6'}
                            spacing={isSmallDevice ? 4 : 12}>
                            {breadcrumbs && (
                                <Breadcrumbs
                                    {...{
                                        breadcrumbs,
                                        iconColor: 'primary.main',
                                    }}
                                />
                            )}
                            {NavbarComponent ? (
                                <NavbarComponent />
                            ) : (
                                title && <NavBar title={title} />
                            )}
                        </Stack>
                        <ErrorBoundary fallback={ErrorPage}>
                            <Box {...boxProps}>{children}</Box>
                        </ErrorBoundary>
                        {!isSmallDevice && <Footer />}
                    </Stack>
                </Flex>
            </HStack>
            {isSmallDevice && (
                <CoreMenu
                    links={allowedMenuEntries}
                    width={MENU_WIDTH}
                    resetFilters={setSelectedFilters}
                    intlMessages={sharedMessages}
                />
            )}
        </LayoutBoundary>
    );
}
