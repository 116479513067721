import { MessageDescriptor, useIntl } from 'react-intl';
import Alert from 'design-system/components/Alert';

type AlertErrorProps = { error: MessageDescriptor };

export function AlertError({ error }: AlertErrorProps) {
    const { formatMessage } = useIntl();

    return (
        <Alert
            color="error"
            icon="WarningIcon"
            description={formatMessage(error)}
        />
    );
}
