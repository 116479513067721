import { SelectedFilter, SelectedFilterDate, SelectedFilterText } from 'core';
import { UseFormReturn } from 'react-hook-form';

const formatValueArray = (value, name): SelectedFilterText => ({
    name,
    type: 'text',
    values: value
        .filter(
            (jsonEncodedValue, idx) => value.indexOf(jsonEncodedValue) === idx
        )
        ?.map((jsonEncodedValue) => JSON.parse(jsonEncodedValue)),
});

export function onFiltersSubmit(
    getValues: UseFormReturn['getValues'],
    setSelectedFilters: React.Dispatch<React.SetStateAction<SelectedFilter[]>>,
    setIsFiltersOpened: React.Dispatch<React.SetStateAction<boolean>>
) {
    const selectedFilters = Object.entries(getValues())
        ?.filter(
            ([_, value]) =>
                !!value?.length ||
                !!value?.value?.length ||
                value?.startDate ||
                value?.endDate
        )
        ?.map(([name, value]) => {
            if (value?.startDate || value?.endDate) {
                return {
                    name,
                    type: 'date',
                    values: value,
                } as SelectedFilterDate;
            }

            if (Array.isArray(value)) {
                return formatValueArray(value, name);
            }

            if (Array.isArray(value?.value)) {
                return formatValueArray(value.value, name);
            }

            if (typeof value === 'string') {
                return {
                    name,
                    type: 'text',
                    values: [JSON.parse(value)],
                } as SelectedFilterText;
            }

            return {
                name,
                type: 'text',
                values: [value?.value],
            } as SelectedFilterText;
        });
    setSelectedFilters(selectedFilters);
    setIsFiltersOpened(false);
}
