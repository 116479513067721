import axios from 'axios';
import { useQuery } from '@tanstack/react-query';
import { APIError, FetchQueryParams, FormObject, FormTypesEnum } from 'core';

const basePath = '/api/proxy/employer-beneficiary-designation/admin';

export const addContractFormPath = `${basePath}/companies/[id]/contracts`;

export const editContractFormPath = `${basePath}/contracts/[id]`;

export async function fetchContractForm({
    queryKey: [urn, id],
}: FetchQueryParams) {
    try {
        const response = await axios.options(urn.replace('[id]', id));
        return response.data.data as FormObject;
    } catch (error) {
        throw {
            code: error?.response?.status,
            customErrorCode: error?.response?.data?.errors?.[0]?.code,
        };
    }
}

export function useContractForm(id: string, type: FormTypesEnum) {
    return useQuery<FormObject, APIError>({
        queryKey: [
            type === FormTypesEnum.ADD
                ? addContractFormPath
                : editContractFormPath,
            id,
            'OPTIONS',
        ],

        queryFn: fetchContractForm,
    });
}
