import axios from 'axios';
import { APIError, FetchQueryParams } from 'core';
import { useQuery } from '@tanstack/react-query';
import { Clause } from '../types';

export const clausePath = `/api/proxy/employer-beneficiary-designation/admin/clauses/[id]`;

async function fetchClause({ queryKey: [urn, id] }: FetchQueryParams) {
    try {
        const res = await axios.get(urn.replace('[id]', id));

        return res.data.data;
    } catch (error) {
        throw {
            code: error.response?.status,
        };
    }
}

export function useClause(id: string) {
    return useQuery<Clause, APIError>({
        queryKey: [clausePath, id],
        queryFn: fetchClause,
    });
}
