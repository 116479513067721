import React from 'react';
import { HStack, Text, Link } from '@chakra-ui/react';
import { LinkIntl } from 'core';
import { ChevronRightIcon } from 'design-system/icons';
import { HomeLinkType } from 'lib/home';

type HomeLinkProps = Pick<HomeLinkType, 'text' | 'link' | 'clickHandler'>;

export default function HomeLink({ text, link, clickHandler }: HomeLinkProps) {
    return (
        <Link
            // @ts-ignore
            href={link}
            {...(link && {
                as: LinkIntl,
                passHref: true,
            })}
            {...(clickHandler && { onClick: clickHandler })}>
            <HStack>
                <Text fontSize="medium" fontWeight={500} color="blue.700">
                    {text as string}
                </Text>
                <ChevronRightIcon w={5} h={5} />
            </HStack>
        </Link>
    );
}
