export const NOT_AUTH_ROUTES = [
    '/login',
    '/reset-password/[token]',
    '/activation',
    '/account-activation/[token]',
    '/lost-password',
    '/lost-credentials/error/[status]',
    '/mfa',
    '/mfa/[strategy]/[id]',
    '/404',
    '/password-upgrade/[token]',
];
