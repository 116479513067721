import { Flex, HStack, Text, VStack } from '@chakra-ui/react';
import { uniqueId } from 'lodash';
import React from 'react';
import { HomeCardType } from '../../lib/home';
import HomeLink from './HomeLink';
import HomeLinkOverlay from './HomeLinkOverlay';

export type HomeCardProps = Pick<
    HomeCardType,
    'title' | 'Icon' | 'facetLinks' | 'shortcutLinks'
>;

export default function HomeCard({
    title,
    Icon,
    facetLinks,
    shortcutLinks,
}: HomeCardProps) {
    return (
        <VStack bg="white" p={6} alignItems="flex-start" w="100%" h="100%">
            <HStack>
                <Flex
                    w="44px"
                    h="44px"
                    fontSize="x-large"
                    bg="primary.main"
                    color="white"
                    justifyContent="center"
                    alignItems="center"
                    rounded="full">
                    {Icon && <Icon />}
                </Flex>
                <Text fontSize="md" fontWeight="600">
                    {title as string}
                </Text>
            </HStack>
            <VStack
                pt={4}
                justifyContent="space-between"
                alignItems="flex-start"
                w="100%"
                h="100%">
                {facetLinks.length && (
                    <VStack alignItems={'flex-start'} spacing={3} w="100%">
                        {facetLinks?.map((link) => (
                            <HomeLinkOverlay key={uniqueId()} {...link} />
                        ))}
                    </VStack>
                )}
                <VStack alignItems={'flex-start'} spacing={3}>
                    {shortcutLinks?.map((link) => (
                        <HomeLink key={uniqueId()} {...link} />
                    ))}
                </VStack>
            </VStack>
        </VStack>
    );
}
