import { useQuery } from '@tanstack/react-query';
import axios, { AxiosResponse } from 'axios';
import { APIError, FetchQueryParams } from 'core';

const contractFilePath =
    '/api/proxy/employer-beneficiary-designation/admin/contracts/[id]/file';

async function fetchContractFile({
    queryKey: [urn, contractId],
}: FetchQueryParams) {
    try {
        return await axios.get(urn?.replace('[id]', contractId), {
            responseType: 'arraybuffer',
        });
    } catch (error) {
        throw {
            code: error?.response?.status,
        };
    }
}

export function useContractFile(contractId: string) {
    return useQuery<AxiosResponse, APIError>({
        queryKey: [contractFilePath, contractId],
        queryFn: fetchContractFile,
    });
}
