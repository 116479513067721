import {
    HStack,
    Text,
    Box,
    LinkBox,
    LinkOverlay,
    Flex,
} from '@chakra-ui/react';
import React from 'react';
import { LinkIntl } from 'core';
import { ChevronRightIcon } from 'design-system/icons';
import { HomeLinkOverlayType } from 'lib/home';

type HomeLinkOverlayProps = HomeLinkOverlayType;

export default function HomeLinkOverlay({
    text,
    link,
    numberOfItems,
}: HomeLinkOverlayProps) {
    const _renderLink = () => {
        return (
            <Box w="inherit" paddingLeft={2}>
                <LinkOverlay
                    as={LinkIntl}
                    // @ts-ignore
                    href={link}
                    passHref>
                    <Flex alignItems="center" justifyContent="space-between">
                        <Text fontWeight="400" fontSize="sm">
                            {text as string}
                        </Text>
                        <ChevronRightIcon
                            transition="ease-in-out all 0.3s"
                            w={7}
                            h={7}
                        />
                    </Flex>
                </LinkOverlay>
            </Box>
        );
    };

    return (
        <LinkBox
            w="inherit"
            p={2}
            rounded="md"
            _hover={{
                bg: 'grey.50',
                '.arrow-icon': {
                    transform: 'translateX(-10px)',
                    transition: 'ease-in-out all 0.3s',
                },
            }}
            _focusWithin={{
                '.arrow-icon': {
                    transform: 'translateX(-10px)',
                    transition: 'ease-in-out all 0.3s',
                },
            }}>
            <HStack w="inherit">
                <Flex
                    minW="50px"
                    minH="50px"
                    p={2}
                    border="1px"
                    justifyContent="center"
                    alignItems="center"
                    borderColor="gray.300"
                    rounded="full"
                    color="primary.main"
                    fontSize="lg"
                    fontWeight={600}>
                    {numberOfItems}
                </Flex>
                {_renderLink()}
            </HStack>
        </LinkBox>
    );
}
