import { Global, css } from '@emotion/react';

export function FontFaces() {
    return (
        <Global
            styles={css`
                @font-face {
                    font-family: 'Lato';
                    font-style: normal;
                    font-weight: 300;
                    src: url('/fonts/lato/Lato-Light.ttf') format('truetype');
                }
                @font-face {
                    font-family: 'Lato';
                    font-style: normal;
                    font-weight: 400;
                    src: url('/fonts/lato/Lato-Regular.ttf') format('truetype');
                }
                @font-face {
                    font-family: 'Lato';
                    font-style: normal;
                    font-weight: 500;
                    src: url('/fonts/lato/Lato-Medium.ttf') format('truetype');
                }
                @font-face {
                    font-family: 'Lato';
                    font-style: normal;
                    font-weight: 600;
                    src: url('/fonts/lato/Lato-Semibold.ttf') format('truetype');
                }
                @font-face {
                    font-family: 'Lato';
                    font-style: normal;
                    font-weight: 700;
                    src: url('/fonts/lato/Lato-Bold.ttf') format('truetype');
                }
                @font-face {
                    font-family: 'Lato';
                    font-style: normal;
                    font-weight: 800;
                    src: url('/fonts/lato/Lato-Bold.ttf') format('truetype');
                }
                @font-face {
                    font-family: 'Lato';
                    font-style: normal;
                    font-weight: 900;
                    src: url('/fonts/lato/Lato-Black.ttf') format('truetype');
                }
                @font-face {
                    font-family: 'Lato';
                    font-style: italic;
                    font-weight: 400;
                    src: url('/fonts/lato/Lato-Italic.ttf') format('truetype');
                }
                @font-face {
                    font-family: 'Be Vietnam Pro';
                    font-style: normal;
                    font-weight: 300;
                    src:
                        url('/fonts/be-vietnam-pro/300.woff2') format('woff2'),
                        url('/fonts/be-vietnam-pro/300.woff') format('woff');
                }
                @font-face {
                    font-family: 'Be Vietnam Pro';
                    font-style: normal;
                    font-weight: 400;
                    src:
                        url('/fonts/be-vietnam-pro/regular.woff2')
                            format('woff2'),
                        url('/fonts/be-vietnam-pro/regular.woff') format('woff');
                }
                @font-face {
                    font-family: 'Be Vietnam Pro';
                    font-style: normal;
                    font-weight: 500;
                    src:
                        url('/fonts/be-vietnam-pro/500.woff2') format('woff2'),
                        url('/fonts/be-vietnam-pro/500.woff') format('woff');
                }
                @font-face {
                    font-family: 'Be Vietnam Pro';
                    font-style: normal;
                    font-weight: 600;
                    src:
                        url('/fonts/be-vietnam-pro/600.woff2') format('woff2'),
                        url('/fonts/be-vietnam-pro/600.woff') format('woff');
                }
                @font-face {
                    font-family: 'Be Vietnam Pro';
                    font-style: normal;
                    font-weight: 700;
                    src:
                        url('/fonts/be-vietnam-pro/700.woff2') format('woff2'),
                        url('/fonts/be-vietnam-pro/700.woff') format('woff');
                }
                @font-face {
                    font-family: 'Be Vietnam Pro';
                    font-style: normal;
                    font-weight: 800;
                    src:
                        url('/fonts/be-vietnam-pro/800.woff2') format('woff2'),
                        url('/fonts/be-vietnam-pro/800.woff') format('woff');
                }
                @font-face {
                    font-family: 'Be Vietnam Pro';
                    font-style: italic;
                    font-weight: 400;
                    src:
                        url('/fonts/be-vietnam-pro/italic.woff2')
                            format('woff2'),
                        url('/fonts/be-vietnam-pro/italic.woff') format('woff');
                }
                @font-face {
                    font-family: 'Dancing Script';
                    font-style: normal;
                    font-weight: 400;
                    src: url('/fonts/dancing-script/400.ttf') format('truetype');
                }
            `}
        />
    );
}
