import { clausePath } from './useClause';
import { useMutation } from '@tanstack/react-query';
import { APIError } from 'core';
import axios from 'axios';
import { getErrorCode } from 'lib/shared/helpers';

export const CLAUSE_IN_USE_ERROR = 'c5116e84-fc8d-431e-862a-f3c5d8dda481';

async function deleteClause(path: string, id: string) {
    try {
        const res = await axios.delete(path.replace('[id]', id));

        return res.data.data;
    } catch (error) {
        throw {
            code: getErrorCode(error, CLAUSE_IN_USE_ERROR),
        };
    }
}

export function useDeleteClause() {
    return useMutation<unknown, APIError, unknown>({
        mutationFn: (id: string) => deleteClause(clausePath, id),
    });
}
