import { useMutation } from '@tanstack/react-query';
import { APIError } from 'core';
import axios from 'axios';
import { getErrorCode } from '../../shared/helpers';

export const CANNOT_DELETE_CONTRACT_ERROR =
    '931a0e65-4bf4-44f9-8c40-7041e8e047dc';

export const deleteContractPath =
    '/api/proxy/employer-beneficiary-designation/admin/contracts/[id]';

async function deleteContract(path: string, id: string) {
    try {
        const res = await axios.delete(path.replace('[id]', id));

        return res.data.data;
    } catch (error) {
        throw {
            code: getErrorCode(error, CANNOT_DELETE_CONTRACT_ERROR),
        };
    }
}

export function useDeleteContract() {
    return useMutation<unknown, APIError, unknown>({
        mutationFn: (id: string) => deleteContract(deleteContractPath, id),
    });
}
