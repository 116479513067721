import React from 'react';
import { FormattedMessage, MessageDescriptor } from 'react-intl';
import { Container, Stack, Text } from '@chakra-ui/react';
import {
    EmptyIllustration,
    LoadingCard,
    coreSharedMessages,
    coreErrors,
    coreEmptyStates,
    LayoutButtonType,
    defaultLayoutBoundaryErrorMessages,
    LayoutBoundaryType,
} from 'core';
import { useWindowBreakpoints } from 'design-system/hooks';
import { ToastError } from 'design-system/components';
import { AlertError, Error } from 'core/components/Layouts/ErrorComponent';
import { CONTAINER_WIDTHS } from 'components/consts';
import { LayoutButtons } from 'core/components/Layouts/LayoutButton';

const MESSAGES = {
    loading: coreSharedMessages.loading,
    error: coreErrors.error,
    empty: coreEmptyStates.default,
    maintenanceTitle: coreErrors.maintenanceTitle,
    maintenanceDescription: coreErrors.maintenanceDescription,
};

type ComponentProps = {
    message?: MessageDescriptor;
};

function Loading({ message = MESSAGES.loading }: ComponentProps) {
    return (
        <LoadingCard>
            <FormattedMessage {...message} />
        </LoadingCard>
    );
}

export function Empty({
    message = MESSAGES.empty,
    buttons = [],
}: ComponentProps & { buttons: LayoutButtonType[] }) {
    const { isMobile } = useWindowBreakpoints();

    return (
        <Stack
            rounded="3xl"
            bg="white"
            p={isMobile ? '4' : '9'}
            width="full"
            mt={isMobile ? '4' : '8'}>
            <EmptyIllustration p="inherit">
                <Text
                    fontWeight="semibold"
                    fontSize="md"
                    color="grey.600"
                    width={isMobile ? '100%' : ''}>
                    <FormattedMessage {...message} />

                    {buttons && <LayoutButtons buttons={buttons} />}
                </Text>
            </EmptyIllustration>
        </Stack>
    );
}

export type LayoutBoundaryProps = LayoutBoundaryType &
    React.PropsWithChildren<{
        EmptyComponent?: React.ComponentType<
            ComponentProps & { buttons: LayoutButtonType[] }
        >;
        emptyButtons?: LayoutButtonType[];
        isSmallDisplay?: boolean;
    }>;

export default function LayoutBoundary({
    children,
    status = 'success',
    toastError,
    alertError,
    errorValues,
    ErrorComponent = Error,
    LoadingComponent = Loading,
    EmptyComponent = Empty,
    messages,
    errorCode,
    size = CONTAINER_WIDTHS.PRIMARY,
    errorButtons,
    emptyButtons,
    isSmallDisplay = false,
    ...boxProps
}: LayoutBoundaryProps) {
    return (
        <Container as="main" size={size} maxW="100%" margin="0" {...boxProps}>
            {alertError && (
                <AlertError
                    message={messages?.alertError}
                    errorValues={errorValues}
                />
            )}
            {status === 'pending' && (
                <LoadingComponent message={messages?.loading} />
            )}
            {status === 'error' && (
                <ErrorComponent
                    message={
                        messages?.error ||
                        defaultLayoutBoundaryErrorMessages[errorCode]?.error
                    }
                    description={
                        messages?.errorDescription ||
                        defaultLayoutBoundaryErrorMessages[errorCode]
                            ?.errorDescription
                    }
                    errorCode={errorCode}
                    errorButtons={errorButtons}
                    isSmallDisplay={isSmallDisplay}
                    bg="white"
                />
            )}
            {status === 'empty' && (
                <>
                    {children}
                    <EmptyComponent
                        message={messages?.empty}
                        buttons={emptyButtons}
                    />
                </>
            )}
            {status === 'maintenance' && (
                <ErrorComponent
                    message={
                        messages?.maintenanceTitle || MESSAGES.maintenanceTitle
                    }
                    description={
                        messages?.maintenanceDescription ||
                        MESSAGES.maintenanceDescription
                    }
                    isSmallDisplay={isSmallDisplay}
                    bg="white"
                />
            )}
            {status === 'success' && children}
            {toastError && (
                <ToastError
                    message={messages?.toastError}
                    title={messages?.toastTitleError}
                    errorValues={errorValues}
                />
            )}
        </Container>
    );
}
