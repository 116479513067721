import React from 'react';
import { interceptorMessages, useErrorInterceptor } from 'lib/shared';
import {
    defineMessage,
    defineMessages,
    FormattedMessage,
    useIntl,
} from 'react-intl';
import { Heading, LinkIntl, coreSharedMessages, useInterceptor } from 'core';
import { useWindowBreakpoints } from 'design-system/hooks';
import { ToastError } from 'design-system/components';
import {
    AlertDialog,
    AlertDialogBody,
    AlertDialogContent,
    AlertDialogFooter,
    AlertDialogOverlay,
    Button,
    Flex,
    Img,
    Stack,
    Text,
} from '@chakra-ui/react';
import { useRouter } from 'next/router';

const TOKEN_EXCLUDED_PATHS = [
    '/login',
    '/mfa',
    '/mfa/[strategy]',
    '/reset-password/[providerKey]',
];
const ERROR_EXCLUDED_PATHS = [
    '/login',
    '/mfa',
    '/mfa/[strategy]',
    '/forgot-password',
    '/forgot-username',
    '/reset-password/[providerKey]',
];

const intlMessage = defineMessage({
    id: 'components.session-expired-modal.image-alt',
    defaultMessage: 'Icône de session expirée',
});

const pathRelatedConfig = {
    '/reset-password/[token]': {
        ...defineMessages({
            title: {
                id: 'errors.timeout-link.title',
                defaultMessage: 'Lien expiré',
            },
            description: {
                id: 'errors.timeout-link.description',
                defaultMessage:
                    'Votre lien a expiré, veuillez renouveler votre demande.',
            },
        }),
        button: coreSharedMessages.backToLogin,
        illustration: '/images/illustrations/expired-link.svg',
    },
    '/account-activation/[token]': {
        ...defineMessages({
            title: {
                id: 'errors.invalid-link.title',
                defaultMessage: 'Lien invalide',
            },
            description: {
                id: 'errors.invalid-link.description',
                defaultMessage:
                    'Il est possible que le lien ait expiré ou que votre compte ait déjà été activé.',
            },
        }),
        button: coreSharedMessages.backToLogin,
        illustration: '/images/illustrations/expired-link.svg',
    },
    default: {
        title: defineMessage({
            id: 'errors.timeout-session.title',
            defaultMessage: 'Session expirée',
        }),
        description: interceptorMessages[401].default,
        button: coreSharedMessages.loginAgain,
        illustration: '/images/illustrations/warning.svg',
    },
};

function TokenExpiredAlertDialog({ pathname }: { pathname: string }) {
    const { formatMessage } = useIntl();
    const { isMobile } = useWindowBreakpoints();

    return (
        <AlertDialog
            isOpen
            leastDestructiveRef={undefined}
            closeOnEsc={false}
            closeOnOverlayClick={false}
            onClose={() => undefined}
            isCentered={!isMobile}>
            <AlertDialogOverlay>
                <AlertDialogContent
                    rounded="3xl"
                    {...(isMobile && {
                        position: 'absolute',
                        bottom: '-60px',
                        roundedBottom: 'none',
                    })}>
                    <AlertDialogBody
                        p="0"
                        borderBottom="1px solid"
                        borderBottomColor="strokes.medium">
                        <Flex
                            w="full"
                            justifyContent="center"
                            bg="grey.50"
                            py="8"
                            px="4"
                            rounded="3xl"
                            roundedBottom={isMobile && 'none'}>
                            <Stack alignItems="center">
                                <Img
                                    alt={formatMessage(intlMessage)}
                                    src={
                                        pathRelatedConfig[
                                            pathRelatedConfig?.[pathname]
                                                ? pathname
                                                : 'default'
                                        ].illustration
                                    }
                                    htmlWidth="160px"
                                />
                                <Heading as="h2" color="primary.main">
                                    <FormattedMessage
                                        {...pathRelatedConfig[
                                            pathRelatedConfig?.[pathname]
                                                ? pathname
                                                : 'default'
                                        ].title}
                                    />
                                </Heading>
                                <Text color="primary.main">
                                    <FormattedMessage
                                        {...pathRelatedConfig[
                                            pathRelatedConfig?.[pathname]
                                                ? pathname
                                                : 'default'
                                        ].description}
                                    />
                                </Text>
                            </Stack>
                        </Flex>
                    </AlertDialogBody>

                    <AlertDialogFooter py="6">
                        <Button
                            as={LinkIntl}
                            href="/login"
                            colorScheme="primary"
                            w="full">
                            <FormattedMessage
                                {...pathRelatedConfig[
                                    pathRelatedConfig?.[pathname]
                                        ? pathname
                                        : 'default'
                                ].button}
                            />
                        </Button>
                    </AlertDialogFooter>
                </AlertDialogContent>
            </AlertDialogOverlay>
        </AlertDialog>
    );
}

export function ErrorInterceptor() {
    const router = useRouter();
    const { error } = useInterceptor(useErrorInterceptor);

    if (
        !TOKEN_EXCLUDED_PATHS.includes(router.pathname) &&
        error?.status === 401
    ) {
        return <TokenExpiredAlertDialog pathname={router.pathname} />;
    }

    if (
        !ERROR_EXCLUDED_PATHS.includes(router.pathname) &&
        [400, 403, 404, 500, 503, 504].includes(error?.status)
    ) {
        return <ToastError message={error.message} />;
    }

    return null;
}
