import { useInfiniteQuery } from '@tanstack/react-query';
import axios, { AxiosRequestConfig } from 'axios';
import {
    APIError,
    groupInfiniteQuery,
    useSafeIntl,
    DashboardContext,
    SelectedFilter,
    getFiltersQuery,
} from 'core';
import React from 'react';
import { getNextPageParam } from 'core/lib/shared/helpers/getNextPageParam';
import { Clause } from 'lib/clauses/types';
import { ListTableRow } from 'components/ListTable/ListTable';
import { sharedMessages } from 'lib/shared';
import { defineMessages } from 'react-intl';
import { FileFileIcon } from 'design-system/icons';

type FetchClausesApi = Api<Clause[], null, { pagination: Pagination }>;

const clausesPath = '/api/proxy/employer-beneficiary-designation/admin/clauses';

const listMessages = {
    ...defineMessages({
        contract: {
            id: 'components.management-contracts-number',
            defaultMessage:
                '{nbContract, plural, =0 {Aucun contrat} one {# contrat} other {# contrats}}',
        },
    }),
};
async function fetchClauses(
    config: AxiosRequestConfig,
    safeFormatMessage: Function,
    mergedFilters: SelectedFilter[]
) {
    try {
        const mergedFiltersQuery = getFiltersQuery(mergedFilters);

        const { data: response } = await axios.get(
            `${clausesPath}${mergedFiltersQuery && `?${mergedFiltersQuery}`}`,
            {
                ...config,
            }
        );
        const { data, meta }: FetchClausesApi = response;

        return {
            pagination: meta?.pagination,
            clauses: data.map((clause) => [
                {
                    type: 'name',
                    data: {
                        name: {
                            labelOne: clause.name,
                            labelTwo: null,
                            Icon: FileFileIcon,
                            iconHasBg: true,
                            isFirstColumn: true,
                        },

                        type: safeFormatMessage(
                            sharedMessages[clause.type],
                            null,
                            `<Clauses />${clause.type}`
                        ),

                        linkParams: {
                            clauseId: clause.identifier,
                        },
                    },
                },
                {
                    type: 'ClauseContracts',
                    data: {
                        related: {
                            text: safeFormatMessage(
                                sharedMessages['linkedTo'],
                                {
                                    gender: 'female',
                                }
                            ),
                        },
                        tag: {
                            bg: clause.numberOfContracts
                                ? 'blue.50'
                                : 'grey.100',
                            color: clause.numberOfContracts
                                ? 'blue.700'
                                : 'grey.800',
                            isInactive: !clause.numberOfContracts,
                            formattedText: safeFormatMessage(
                                listMessages.contract,
                                {
                                    nbContract: clause.numberOfContracts,
                                }
                            ),
                        },
                    },
                },
                {
                    type: 'creationDate',
                    data: {
                        text: safeFormatMessage(sharedMessages.created),
                        date: clause.creationDate,
                    },
                },
                {
                    type: 'modificationDate',
                    data: {
                        text: safeFormatMessage(sharedMessages.modified),
                        date: clause.modificationDate,
                    },
                },
            ]),
        };
    } catch (err) {
        throw { code: err.response?.status };
    }
}

function useClauses() {
    const { safeFormatMessage } = useSafeIntl();
    const { selectedFilters, currentPage } = React.useContext(DashboardContext);
    const start = (currentPage - 1) * 10 + 1;
    const arrayUniqueId = btoa(JSON.stringify(selectedFilters));

    const { data, ...queryProps } = useInfiniteQuery<any, APIError>({
        initialPageParam: start,
        queryKey: [clausesPath, 'GET', start, arrayUniqueId],
        queryFn: ({ pageParam = start }) =>
            fetchClauses(
                {
                    params: {
                        start: pageParam,
                        count: 10,
                    },
                },
                safeFormatMessage,
                selectedFilters
            ),
        getNextPageParam,
    });
    const clauses = groupInfiniteQuery<ListTableRow>('clauses', data);

    return {
        clauses,
        ...data,
        ...queryProps,
    };
}

export { clausesPath, fetchClauses, useClauses };
