import { Environment } from '../../environments/types';
import { getResourceType } from 'lib/shared/helpers';
import { CompanyDetails } from '../../companies/types';
import { Group } from '../../groups/types';
import axios from 'axios';
import { useQuery } from '@tanstack/react-query';
import { APIError, FetchQueryParams, FormObject } from 'core';

export const themeFormPath = '/api/proxy/pe-admin/[resource]/[id]/theme';

export async function fetchThemeForm({
    queryKey: [urn, id],
}: FetchQueryParams) {
    try {
        const response = await axios.options(urn.replace('[id]', id));
        return response.data.data as FormObject;
    } catch (error) {
        throw {
            code: error?.response?.status,
        };
    }
}

export function useThemeForm(resource: Group | CompanyDetails | Environment) {
    const customizedPath = themeFormPath.replace(
        '[resource]',
        getResourceType(resource)
    );

    return useQuery<FormObject, APIError>({
        queryKey: [customizedPath, resource.identifier, 'OPTIONS'],
        queryFn: fetchThemeForm,
    });
}
