import { FormObject } from 'core';

export function autoSelectStatus(form: FormObject, status: string) {
    return {
        ...form,
        widgets: form.widgets.map((widget) => {
            if (widget.id === 'employeeStatus') {
                return {
                    ...widget,
                    options: widget?.options?.map((widgetOption) => {
                        return {
                            ...widgetOption,
                            isDefaultValue: widgetOption.id === status,
                        };
                    }),
                };
            }
            return widget;
        }),
    };
}
