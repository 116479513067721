import { Text, TextProps } from '@chakra-ui/react';
import { Heading } from 'core';

type HeaderTitleProps = React.PropsWithChildren<
    TextProps & {
        type?: 'primary' | 'secondary';
    }
>;

function HeaderTitle({ children, ...props }: HeaderTitleProps) {
    return (
        <Text as={Heading} {...props}>
            {children}
        </Text>
    );
}

export { HeaderTitle };
