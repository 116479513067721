import React from 'react';
import { useRouter } from 'next/router';
import {
    capitalizeHyphenatedName,
    PermissionsContext,
    PermissionsContextType,
    useUser,
} from 'core';
import { contextError } from 'core/utils';
import { NOT_AUTH_ROUTES } from './consts';
import { ThemeContext } from 'themes';
import type { EnterpriseUser } from 'core/lib/auth';

type UserInfosContext =
    | (EnterpriseUser.UserInfos & {
          setUserInfos: (userInfos: EnterpriseUser.UserInfos) => void;
      })
    | null
    | undefined;

type InitialsProps = {
    children: React.ReactNode;
    defaultUserInfos?: UserInfosContext;
};

const UserContext = React.createContext<UserInfosContext>(null);

function useUserContext() {
    const context = React.useContext(UserContext);
    if (!context) {
        contextError('useUserInitialsContext', 'UserInitialsProvider');
    }
    return context;
}

function capitalizeUserName(userInfo: EnterpriseUser.UserInfos) {
    return {
        ...userInfo,
        user: {
            ...userInfo.user,
            firstName: capitalizeHyphenatedName(
                userInfo?.user?.firstName ?? ''
            ),
            lastName: capitalizeHyphenatedName(userInfo?.user?.lastName ?? ''),
        },
    };
}

function UserProvider({ children, defaultUserInfos = null }: InitialsProps) {
    const router = useRouter();
    const [userInfos, setUserInfos] =
        React.useState<EnterpriseUser.UserInfos>(defaultUserInfos);
    const { data: userData } = useUser(
        'enterprise-admin',
        !NOT_AUTH_ROUTES.includes(router?.pathname)
    ) as { data: EnterpriseUser.UserInfos };
    const { setCustomTheme } = React.useContext(ThemeContext);
    const { setPermissions } =
        React.useContext<PermissionsContextType<string[]>>(PermissionsContext);

    // Gestion de tous les effets secondaires lié à l'appel api /user/account
    React.useEffect(() => {
        if (!userData) return;

        if (userData?.theme && userData?.user) {
            setUserInfos(capitalizeUserName(userData));
            setCustomTheme({
                primaryColor: userData?.theme?.primaryColor,
            });
            setPermissions(userData?.permissions);
        } else {
            setUserInfos({
                user: null,
                theme: null,
                permissions: [],
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userData]);

    return (
        <UserContext.Provider value={{ ...userInfos, setUserInfos }}>
            {children}
        </UserContext.Provider>
    );
}

export type { UserInfosContext, InitialsProps };
export { UserContext, UserProvider, useUserContext };
