/**
 * This function takes in an error object and a code to catch in case of 403 and returns a string indicating the error code.
 * @param error An object that represents the error returned by Axios.
 * @param codeToCatch A string that represents the error code that needs to be caught.
 * @returns catched error or status of the response
 */
export function getErrorCode(error, codeToCatch: string): string {
    return error?.response.status === 403 &&
        error?.response?.data?.errors[0]?.code === codeToCatch
        ? error.response.data.errors[0].code
        : error?.response.status;
}
