import React from 'react';
import { useRouter } from 'next/router';
import { ThemeContext } from 'themes';
import { UseInterceptorObject } from 'core';

function useErrorInterceptor() {
    const router = useRouter();
    const [state, setState] = React.useState<UseInterceptorObject>(null);
    const { setCustomTheme } = React.useContext(ThemeContext);

    function onError(err: any) {
        const { status } = err.response;

        if (status === 401) {
            setCustomTheme(undefined);
            setState((state: UseInterceptorObject) => ({
                ...state,
                status,
            }));
        }

        throw err;
    }

    // Au changement de route, on reset les erreurs
    React.useEffect(() => {
        setState({
            route: router.route,
            status: null,
        });
    }, [router.route]);

    return { onError, state };
}

export { useErrorInterceptor };
