import { FieldValues, UseFormReturn } from 'react-hook-form';
import { SelectedFilter } from 'core';

export function handleFullReset(
    setIsFiltersOpened: (value: boolean) => void,
    setSelectedFilters: (value: React.SetStateAction<SelectedFilter[]>) => void,
    methods: UseFormReturn<FieldValues>
) {
    const resetAllFields = (value: string | null) => {
        const emptyValues = Object.keys(methods.getValues()).reduce(
            (prev, curr) => ({
                ...prev,
                [curr]: value,
            }),
            {}
        );

        methods.reset(emptyValues);
    };

    setIsFiltersOpened(false);
    setSelectedFilters([]);

    resetAllFields(null);
    /* We had to use this trick to force resetting all fields in the DOM, due to a bug in react-hook-forms
    See https://github.com/react-hook-form/react-hook-form/discussions/7589 */
    setTimeout(() => resetAllFields(''), 100);
}

export function handleReset(
    setSelectedFilters: (value: React.SetStateAction<SelectedFilter[]>) => void,
    methods: UseFormReturn<FieldValues>,
    filterName: string
) {
    const resetField = (value: string | null) => {
        methods.reset({ ...methods.getValues(), [filterName]: value });
    };

    setSelectedFilters((prev) =>
        prev.filter((filter) => filter.name !== filterName)
    );

    resetField(null);
    setTimeout(() => resetField(''), 100);
}
