import { CompanyDetails } from 'lib/companies';
import { Environment } from 'lib/environments';
import { Group } from 'lib/groups';
import { ResourcesTypeEnum } from '../consts';

export function isCompany(
    data: Group | CompanyDetails | Environment
): data is CompanyDetails {
    return data && 'group' in data;
}

export function isGroup(
    data: Group | CompanyDetails | Environment
): data is Group {
    return data && 'companies' in data;
}

export function getResourceType(
    resource: Group | CompanyDetails | Environment
): ResourcesTypeEnum {
    return isGroup(resource)
        ? ResourcesTypeEnum.GROUPS
        : isCompany(resource)
        ? ResourcesTypeEnum.COMPANIES
        : ResourcesTypeEnum.ENVIRONMENTS;
}
