import React from 'react';
import { DocumentViewerModal } from 'core';
import { useContractFile } from 'lib/contracts';
type documentViewerProps = {
    isOpen: boolean;
    contractId: string;
    fileName: string;
    onClose: () => void;
};

export default function ContractDocument({
    isOpen,
    contractId,
    fileName,
    onClose,
}: documentViewerProps) {
    const { data, status, error } = useContractFile(contractId);

    return (
        <DocumentViewerModal
            {...{ isOpen, onClose, status, error }}
            data={data}
            fileName={fileName}
        />
    );
}
