import React from 'react';
import { Box, Flex, VStack, Text, Img } from '@chakra-ui/react';
import { defineMessages, FormattedMessage } from 'react-intl';
import { coreSharedMessages, getBase64MimeType } from 'core';
import { useWindowBreakpoints } from 'design-system/hooks';
import { UserContext } from 'lib/user';

const intlMessages = defineMessages({
    description: {
        id: 'home.pe.description',
        defaultMessage:
            'Cet espace vous permet de faciliter la gestion de vos contrats de santé collectifs.',
    },
});

type HeaderProps = {
    firstName: string;
};

export default function Header({ firstName }: HeaderProps) {
    const { isSmallDevice } = useWindowBreakpoints();
    const { theme } = React.useContext(UserContext);

    return (
        <Flex
            justifyContent="space-between"
            alignItems={isSmallDevice ? 'flex-start' : 'center'}
            flexDirection={isSmallDevice ? 'column-reverse' : 'row'}>
            <VStack alignItems="flex-start">
                <Text
                    mt={isSmallDevice && 8}
                    fontFamily="heading"
                    fontSize="2.4rem"
                    fontWeight="semibold">
                    <FormattedMessage
                        {...coreSharedMessages.welcomeMessage}
                        values={{
                            firstName,
                        }}
                    />
                </Text>
                <Text>
                    <FormattedMessage {...intlMessages.description} />
                </Text>
            </VStack>
            <Box w={'150px'} h={'60px'}>
                <Img
                    maxW="100%"
                    h="100%"
                    mr="auto"
                    src={
                        theme?.logo
                            ? `data:${getBase64MimeType(theme?.logo)};base64, ${
                                  theme?.logo
                              }`
                            : '/images/logos/home-logo.svg'
                    }
                    {...(!isSmallDevice && { ml: 'auto' })}
                />
            </Box>
        </Flex>
    );
}
