import React from 'react';
import { Box } from '@chakra-ui/react';
import { HeaderBoxType } from 'core';
import { useWindowBreakpoints } from 'design-system/hooks';

export type HeaderBoxProps = HeaderBoxType;

export function HeaderBox({
    children,
    isFixed = false,
    ...boxProps
}: HeaderBoxProps) {
    const { isMobile } = useWindowBreakpoints();

    return (
        <Box
            as="header"
            position={isFixed ? 'sticky' : 'initial'}
            zIndex={isFixed && 10}
            top={isFixed && (isMobile ? 0 : 70)}
            lineHeight="1.2"
            {...boxProps}>
            {children}
        </Box>
    );
}

export function HeaderRoundedColoredBox(props: HeaderBoxProps) {
    return (
        <HeaderBox
            bg="primary.main"
            color="white"
            sx={{
                hr: { opacity: 0.2 },
            }}
            {...props}
        />
    );
}
