import { Box, Container, Divider, Flex, FlexProps } from '@chakra-ui/react';
import { CONTAINER_WIDTH } from 'components/consts';
import { ProgressBarProps } from 'core';
import { useWindowBreakpoints } from 'design-system/hooks';
import { ProgressBar } from 'design-system/components';

type HeaderContainerProps = FlexProps &
    React.PropsWithChildren<{
        isBordered?: boolean;
        progressBar?: ProgressBarProps;
        size?: string;
    }>;

export function HeaderContainer({
    children,
    isBordered = false,
    progressBar,
    size,
    px,
    ...flexProps
}: HeaderContainerProps) {
    const containerProps = {
        size: size || CONTAINER_WIDTH,
        ...(px !== undefined && { px }),
    };
    const { isMobile } = useWindowBreakpoints();

    return (
        <Container {...containerProps} m="0">
            <Flex
                pb={!isBordered ? 4 : 0}
                justifyContent="space-between"
                {...flexProps}>
                {children}
            </Flex>
            {progressBar && (
                <>
                    <ProgressBar
                        value={progressBar?.currentStep || 5}
                        maxValue={progressBar?.maxStep || 10}
                        size="xs"
                        isHeader
                        color={'secondary.main'}
                        my={!isMobile && '5'}
                    />
                    {isMobile && <Box h="5" />}
                </>
            )}
            {!progressBar && isBordered && <Divider my="4" />}
        </Container>
    );
}
