import { defineMessages } from 'react-intl';
import { sharedMessages } from './common';
import { coreSharedMessages } from 'core';

export const relatedActionsMessages = {
    ...defineMessages({
        modifyContract: {
            id: 'related-actions.modify-contract',
            defaultMessage: 'Modifier le contrat',
        },
        deleteContract: {
            id: 'related-actions.delete-contract',
            defaultMessage: 'Supprimer le contrat',
        },
        menuLabel: {
            id: 'components.related-actions-menu.button-label',
            defaultMessage: 'Voir les actions associées',
        },
        deleteEmployee: {
            id: 'related-actions.delete-employee',
            defaultMessage: 'Supprimer le salarié',
        },
        deleteDocument: {
            id: 'related-actions.delete-document',
            defaultMessage: 'Supprimer le document',
        },
        copyCode: {
            id: 'related-actions.copy-code',
            defaultMessage: "Copier le code d'activation",
        },
    }),
    copyUrl: sharedMessages.copyUrl,
    editEmployee: coreSharedMessages.edit,
};
