import axios from 'axios';
import { useQuery } from '@tanstack/react-query';
import { APIError, FetchQueryParams } from 'core';

export const path = `/api/proxy/employer-security/public/legals/[type]`;

export async function fetchTerms({ queryKey: [urn, type] }: FetchQueryParams) {
    try {
        if (!type) return null;

        const response = await axios.get(urn.replace('[type]', type));
        return response.data.data?.text as string;
    } catch (error) {
        throw { code: error.response?.status };
    }
}

export function useTerms(type: string, isEnabled: boolean) {
    return useQuery<string, APIError>({
        queryKey: [path, type],
        queryFn: main.fetchTerms,
        enabled: isEnabled,
    });
}

const main = {
    useQuery,
    fetchTerms,
};
