import { Site } from './types';

export const checkPermissions = (
    requiredPermissions: string[] | string[][],
    userPermissions: string[]
): boolean => {
    let hasRight = false;
    (requiredPermissions as string[][]).every((labelPermission) => {
        if (labelPermission.some((perm) => userPermissions.includes(perm))) {
            hasRight = true;
            return hasRight;
        } else {
            hasRight = false;
            return hasRight;
        }
    });
    return hasRight;
};

export const managePermissionsForLabels = (
    labels: Site.LabelType[],
    userPermissions: string[]
): string => {
    let managedLabel = '';
    if (!userPermissions?.length) return '';
    for (const label of labels) {
        if (Array.isArray(label.permissions[0])) {
            if (checkPermissions(label.permissions, userPermissions)) {
                managedLabel = label.text;
                break;
            }
        }
        if (label.permissions.some((perm) => userPermissions.includes(perm))) {
            managedLabel = label.text;
            break;
        }
    }
    return managedLabel;
};

export const managePermissionsForURLs = (
    links: Site.LinkType[],
    userPermissions: string[],
    defaultURL: string
): string => {
    if (!userPermissions?.length) {
        return defaultURL;
    }

    const managedLink = links.find((link) =>
        link.permissions.some((perm) => userPermissions.includes(perm))
    );

    return managedLink?.url ?? defaultURL;
};
