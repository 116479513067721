type ProfileReason = {
    type: 'form' | 'link';
    path: string;
    query: { [x: string]: string | number };
    translationKey: string;
    available: boolean;
};

export function useProfileReasons(): { data: ProfileReason[][] } {
    return {
        data: [
            [
                {
                    type: 'form',
                    path: '/forms/[formId]',
                    query: {
                        formId: 'email',
                    },
                    translationKey: 'profileEmail',
                    available: true,
                },
                {
                    type: 'form',
                    path: '/forms/[formId]',
                    query: {
                        formId: 'password',
                    },
                    translationKey: 'profilePassword',
                    available: true,
                },
                {
                    type: 'form',
                    path: '/forms/[formId]',
                    query: {
                        formId: 'phones',
                    },
                    translationKey: 'profilePhoneNumbers',
                    available: true,
                },
                {
                    type: 'form',
                    path: '/forms/[formId]',
                    query: {
                        formId: 'emails-frequency',
                    },
                    translationKey: 'profileEmailsFrequency',
                    available: true,
                },
            ],
            [
                {
                    type: 'link',
                    path: '/account/terms/[type]',
                    query: { type: 'legal-notices' },
                    translationKey: 'profileLegalNotices',
                    available: true,
                },
                {
                    type: 'link',
                    path: '/account/terms/[type]',
                    query: { type: 'general-conditions-of-use' },
                    translationKey: 'profileGeneralConditionsOfUse',
                    available: true,
                },
                {
                    type: 'link',
                    path: '/account/terms/[type]',
                    query: { type: 'privacy-policy' },
                    translationKey: 'profilePrivacyPolicy',
                    available: true,
                },
            ],
        ],
    };
}
