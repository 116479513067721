import axios from 'axios';
import { useQuery } from '@tanstack/react-query';
import { APIError, FetchQueryParams, FormObject } from 'core';

export const clauseDetailsFormPath =
    '/api/proxy/employer-beneficiary-designation/admin/clauses';

export async function fetchClauseForm({
    queryKey: [urn, id],
}: FetchQueryParams) {
    try {
        const response = await axios.options(`${urn}${id ? `/${id}` : ''}`);
        return response.data.data as FormObject;
    } catch (error) {
        throw {
            code: error?.response?.status,
        };
    }
}

export function useClauseForm(id?: string) {
    return useQuery<FormObject, APIError>({
        queryKey: [clauseDetailsFormPath, id, 'OPTIONS'],
        queryFn: fetchClauseForm,
    });
}
