import axios from 'axios';
import { useQuery } from '@tanstack/react-query';
import {
    type FormObject,
    type FetchQueryParams,
    type APIError,
    replaceData,
    DataToReplace,
} from 'core';

export type FormPaths = {
    [x: string]: string;
};

export const formPaths: FormPaths = {
    email: '/api/proxy/employer-security/user/email',
    password: '/api/proxy/employer-security/user/password',
    phones: '/api/proxy/employer-security/user/phones',
    'emails-frequency': '/api/proxy/employer-security/user/emails-frequency',
    'lost-password':
        '/api/proxy/employer-security/public/user/password/reset/request',
    activation: '/api/proxy/employer-security/activation/password',
};

export async function fetchForm({ queryKey: [urn] }: FetchQueryParams) {
    try {
        const response = await axios.options(urn);
        return response.data.data as FormObject;
    } catch (error) {
        throw {
            code: error?.response?.status,
        };
    }
}

export function useForm(formName: string, additionalData?: DataToReplace) {
    return useQuery<FormObject, APIError>({
        queryKey: [replaceData(formPaths[formName], additionalData)],
        queryFn: fetchForm,
    });
}
