import { coreSharedMessages } from 'core';
import { defineMessages } from 'react-intl';

export const pageTitles = {
    structures: coreSharedMessages.administration,
    account: coreSharedMessages.account,
    ...defineMessages({
        exemptions: {
            id: 'page-title.exemptions',
            defaultMessage: 'Dispenses d’affiliation',
        },
        activation: {
            id: 'page-title.activation',
            defaultMessage: 'Activation',
        },
        groups: {
            id: 'page-title.groups',
            defaultMessage: 'Groupes',
        },
        companies: {
            id: 'page-title.companies',
            defaultMessage: 'Entreprises',
        },
        users: {
            id: 'page-title.users',
            defaultMessage: 'Utilisateurs',
        },
        roles: {
            id: 'page-title.roles',
            defaultMessage: 'Rôles',
        },
        environments: {
            id: 'page-title.environments',
            defaultMessage: 'Environnements de gestion',
        },
        detail: {
            id: 'page-title.detail',
            defaultMessage: 'Détail',
        },
        clauses: {
            id: 'page-title.clauses',
            defaultMessage: 'Clauses standards',
        },
        contracts: {
            id: 'page-title.contracts',
            defaultMessage: 'Contrats des entreprises',
        },
        inbox: {
            id: 'page-title.inbox',
            defaultMessage: 'Boîte de réception',
        },
        documents: {
            id: 'page-title.legal-documents',
            defaultMessage: 'Mes documents',
        },
    }),
};
