export default {
    "/home": {
        "fr-FR": "/accueil",
        "en-GB": "/home"
    },
    "/account": {
        "fr-FR": "/compte",
        "en-GB": "/account"
    },
    "/account/terms/:type": {
        "fr-FR": "/compte/legal/:type",
        "en-GB": "/account/terms/:type"
    },
    "/groups": {
        "fr-FR": "/groupes",
        "en-GB": "/groups"
    },
    "/groups/:groupId": {
        "fr-FR": "/groupes/:groupId",
        "en-GB": "/groups/:groupId"
    },
    "/companies": {
        "fr-FR": "/entreprises",
        "en-GB": "/companies"
    },
    "/companies/:companyId": {
        "fr-FR": "/entreprises/:companyId",
        "en-GB": "/companies/:companyId"
    },
    "/clauses": {
        "fr-FR": "/clauses",
        "en-GB": "/clauses"
    },
    "/clauses/:clauseId": {
        "fr-FR": "/clauses/:clauseId",
        "en-GB": "/clauses/:clauseId"
    },
    "/beneficiary-designations": {
        "fr-FR": "/designation-beneficiaires",
        "en-GB": "/beneficiary-designations"
    },
    "/contracts": {
        "fr-FR": "/contrats",
        "en-GB": "/contracts"
    },
    "/contracts/:contractId": {
        "fr-FR": "/contrats/:contractId",
        "en-GB": "/contracts/:contractId"
    },
    "/environments": {
        "fr-FR": "/environnements",
        "en-GB": "/environments"
    },
    "/environments/:environmentId": {
        "fr-FR": "/environnements/:environmentId",
        "en-GB": "/environments/:environmentId"
    },
    "/exemptions": {
        "fr-FR": "/dispenses",
        "en-GB": "/exemptions"
    },
    "/exemptions/:employeeId/:exemptionId": {
        "fr-FR": "/dispenses/:employeeId/:exemptionId",
        "en-GB": "/exemptions/:employeeId/:exemptionId"
    },
    "/structures": {
        "fr-FR": "/structures",
        "en-GB": "/structures"
    },
    "/structures/:structureId": {
        "fr-FR": "/structures/:structureId",
        "en-GB": "/structures/:structureId"
    },
    "/login": {
        "fr-FR": "/connexion",
        "en-GB": "/login"
    },
    "/activation": {
        "fr-FR": "/activation",
        "en-GB": "/activation"
    },
    "/reset-password/:token": {
        "fr-FR": "/reinitialiser-mot-de-passe/:token",
        "en-GB": "/reset-password/:token"
    },
    "/account-activation/:token": {
        "fr-FR": "/activation-compte/:token",
        "en-GB": "/account-activation/:token"
    },
    "/logout": {
        "fr-FR": "/deconnexion",
        "en-GB": "/logout"
    },
    "/forms/:formId": {
        "fr-FR": "/formulaires/:formId",
        "en-GB": "/forms/:formId"
    },
    "/lost-password": {
        "fr-FR": "/mot-de-passe-oublie",
        "en-GB": "/lost-password"
    },
    "/lost-credentials/error/:status": {
        "fr-FR": "/identifiants-oublies/erreur/:status",
        "en-GB": "/lost-credentials/error/:status"
    },
    "/password-upgrade/:token": {
        "fr-FR": "/modifier-mot-de-passe/:token",
        "en-GB": "/password-upgrade/:token"
    },
    "/username-upgrade/:username/:token": {
        "fr-FR": "/nouvel-identifiant/:username/:token",
        "en-GB": "/username-upgrade/:username/:token"
    },
    "/roles": {
        "fr-FR": "/roles",
        "en-GB": "/roles"
    },
    "/roles/:roleId": {
        "fr-FR": "/roles/:roleId",
        "en-GB": "/roles/:roleId"
    },
    "/users": {
        "fr-FR": "/utilisateurs",
        "en-GB": "/users"
    },
    "/users/:userId": {
        "fr-FR": "/utilisateurs/:userId",
        "en-GB": "/users/:userId"
    },
    "/legal-documents": {
        "fr-FR": "/documentation-legale",
        "en-GB": "/legal-documents"
    },
    "/legal-documents/:documentId": {
        "fr-FR": "/documentation-legale/:documentId",
        "en-GB": "/legal-documents/:documentId"
    },
    "/document/:source": {
        "fr-FR": "/document/:source",
        "en-GB": "/document/:source"
    },
    "/mfa": {
        "fr-FR": "/mfa",
        "en-GB": "/mfa"
    },
    "/mfa/:strategy/:id": {
        "fr-FR": "/mfa/:strategy/:id",
        "en-GB": "/mfa/:strategy/:id"
    },
    "/privacy-policy": {
        "fr-FR": "/politique-de-confidentialite",
        "en-GB": "/privacy-policy"
    }
}