import { LinkBox } from '@chakra-ui/react';
import ErrorAccordion from 'core/components/errors/ErrorAccordion';
import type { SentryErrorArgs } from 'core/components/errors/types';

export function CardLinkError({ error }: SentryErrorArgs) {
    return (
        <LinkBox
            rounded="md"
            p="4"
            border="1px solid"
            borderColor="strokes.medium"
            boxShadow="md">
            <ErrorAccordion message={error.message} />
        </LinkBox>
    );
}
