import { defineMessage, defineMessages } from 'react-intl';

export const defaultErrorMessages = defineMessages({
    serviceUnavailable: {
        id: 'errors.unavailable-service.text',
        defaultMessage:
            'Ce service est momentanément indisponible. Veuillez réessayer plus tard.',
    },
    serviceNotFound: {
        id: 'errors.not-found-service.text',
        defaultMessage: 'Ce service est indisponible.',
    },
});

const sharedApi400 = {
    default: defaultErrorMessages.serviceUnavailable,
};

const sharedApi401 = defineMessages({
    default: {
        id: 'errors.timeout-session.text',
        defaultMessage: 'Vous avez été déconnecté.',
    },
});

const sharedApi403 = {
    default: defaultErrorMessages.serviceNotFound,
};

const sharedApi404 = {
    default: defaultErrorMessages.serviceNotFound,
};

const sharedApi500 = {
    default: defaultErrorMessages.serviceUnavailable,
};

export const interceptorMessages = {
    default: defineMessage({
        id: 'api.shared.default',
        description: "Message d'erreur de retour api par défaut",
        defaultMessage: 'Une erreur est survenue. Veuillez réessayer.',
    }),
    400: sharedApi400,
    401: sharedApi401,
    403: sharedApi403,
    404: sharedApi404,
    500: sharedApi500,
    502: sharedApi500,
    503: sharedApi500,
    504: sharedApi500,
};
