import { Button } from '@chakra-ui/react';
import { TrashIcon } from 'design-system/icons';
import React from 'react';
import { FormattedMessage, MessageDescriptor } from 'react-intl';
import { useWindowBreakpoints } from 'design-system/hooks';

type DeleteButtonProps = {
    onDelete: () => void;
    title?: MessageDescriptor;
};

export default function DeleteButton({ onDelete, title }: DeleteButtonProps) {
    const { isSmallDevice } = useWindowBreakpoints();

    return (
        <Button
            as={'button'}
            size={'sm'}
            fontWeight={'bold'}
            textDecoration={'underline'}
            variant={'link'}
            colorScheme={'red.500'}
            rightIcon={<TrashIcon />}
            onClick={onDelete}
            {...(isSmallDevice && {
                size: 'md',
                w: '10',
                h: '10',
                sx: {
                    span: {
                        m: 0,
                    },
                },
            })}>
            {!isSmallDevice &&
                (title ? (
                    <FormattedMessage {...title} />
                ) : (
                    <FormattedMessage
                        id="components.form-input-card.item.delete"
                        defaultMessage="Supprimer"
                    />
                ))}
        </Button>
    );
}
