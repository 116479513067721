import { useMutation } from '@tanstack/react-query';
import { APIError } from 'core';
import axios from 'axios';

export const deleteDocumentPath =
    '/api/proxy/employer-beneficiary-designation/admin/contracts/[id]/file';

async function deleteDocument(path: string, id: string) {
    try {
        const res = await axios.delete(path.replace('[id]', id));

        return res.data.data;
    } catch (error) {
        throw {
            code: error.response?.status,
        };
    }
}

export function useDeleteDocument() {
    return useMutation<unknown, APIError, unknown>({
        mutationFn: (id: string) => deleteDocument(deleteDocumentPath, id),
    });
}
