import { Button, IconProps } from '@chakra-ui/react';

type FloatingButtonProps = {
    Icon: React.ComponentType<IconProps>;
    handleOnClick: () => void;
};

export function FloatingButton({ handleOnClick, Icon }: FloatingButtonProps) {
    return (
        <Button
            zIndex={2}
            position="fixed"
            bottom="10%"
            right="5%"
            borderRadius="full"
            p="0"
            w="12"
            h="12"
            colorScheme={'primary'}
            onClick={handleOnClick}>
            <Icon fontSize="lg" />
        </Button>
    );
}
