import React from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { coreSharedMessages, errorToastsMessages } from 'core';
import { useToast } from 'design-system/components';
import DeleteConfirmationModal from 'core/components/modals/DeleteConfirmationModal';
import {
    CANNOT_DELETE_CONTRACT_ERROR,
    useDeleteContract,
} from 'lib/contracts/api/useDeleteContract';
import { contractsPath } from 'lib/contracts';
import { useQueryClient } from '@tanstack/react-query';

type DeleteContractProps = {
    identifier: string;
    name: string;
    isOpen: boolean;
    onClose: () => void;
};

const intlMessages = defineMessages({
    message: {
        id: 'components.confirmation-modal.pe-admin.deletion-contract-text',
        defaultMessage: 'Voulez-vous réellement supprimer le contrat {number} ',
    },
    deleteContractError: {
        id: 'components.confirmation-modal.pe-admin.deletion-contract-text',
        defaultMessage:
            "Le contrat n'a pas pu être supprimé car des données sont rattachées. Vous pouvez uniquement modifier son statut en “Inactif”.",
    },
});

const errorMessages = {
    ...errorToastsMessages,
    [CANNOT_DELETE_CONTRACT_ERROR]: intlMessages.deleteContractError,
};

export default function DeleteContract({
    identifier,
    name,
    isOpen,
    onClose,
}: DeleteContractProps) {
    const { formatMessage } = useIntl();
    const { mutate, isPending } = useDeleteContract();
    const queryClient = useQueryClient();

    const successToast = useToast({
        status: 'success',
        description: formatMessage(coreSharedMessages.deletionToast),
    });

    const errorToast = useToast({
        status: 'error',
    });

    const handleDeleteConfirm = () => {
        mutate(identifier, {
            onSuccess: () => {
                successToast();
                queryClient.invalidateQueries({
                    queryKey: [contractsPath, 'GET'],
                });
            },
            onError: (error) => {
                errorToast({
                    description: formatMessage(
                        errorMessages[error?.code] || coreSharedMessages.error
                    ),
                });
            },
            onSettled: () => {
                onClose();
            },
        });
    };

    return (
        <>
            {isOpen && (
                <DeleteConfirmationModal
                    {...{ isOpen, isPending, onClose }}
                    message={formatMessage(intlMessages.message, {
                        number: name,
                    })}
                    onConfirm={handleDeleteConfirm}
                />
            )}
        </>
    );
}
