import { pageTitles } from 'lib/site';
import { Tab } from './types';

export const CACHE_TIME = 1000 * 60 * 60;

export const MENU_WIDTH = '78px';

export const businessGroupsTabs = [
    {
        name: pageTitles?.groups,
        href: '/groups',
    },
    {
        name: pageTitles?.companies,
        href: '/companies',
    },
];

export enum GroupsCompaniesPathsEnum {
    THEMES = '/themes',
    COMPANIES = '/companies',
    SERVICES = '/features',
    CONTACTS = '/contacts',
}

export enum ResourcesTypeEnum {
    GROUPS = 'groups',
    COMPANIES = 'companies',
    ENVIRONMENTS = 'environments',
}

export enum RolesEnum {
    BUSINESSES_MANAGE = 'businesses_manage',
    USERS_VIEW = 'users_view',
    USERS_MANAGE = 'users_manage',
    ROLES_MANAGE = 'roles_manage',
    EXEMPTIONS_VIEW = 'exemptions_view',
    EXEMPTIONS_PROCESS = 'exemptions_process',
    EXEMPTIONS_CONFIGURE = 'exemptions_configure',
    EXEMPTIONS_PORTAL_VIEW = 'exemptions_portal_view',
    ENVIRONMENTS_MANAGE = 'environments_manage',
    BD_VIEW = 'beneficiary_designation_view',
    BD_CLAUSES_VIEW = 'beneficiary_designation_clauses_view',
    BD_CLAUSES_MANAGE = 'beneficiary_designation_clauses_manage',
    BD_CONTRACTS_VIEW = 'beneficiary_designation_companies_view',
    BD_CONTRACTS_MANAGE = 'beneficiary_designation_companies_manage',
    LEGAL_DOCUMENTS_MANAGE = 'legal_documents_manage',
    LEGAL_DOCUMENTS_VIEW = 'legal_documents_view',
}

export const requiredUsersAndRolesPermissions = [
    [RolesEnum.USERS_VIEW, RolesEnum.USERS_MANAGE],
    [RolesEnum.ROLES_MANAGE],
];

export const userTab: Tab = {
    name: pageTitles?.users,
    href: '/users',
};

export const roleTab: Tab = {
    name: pageTitles?.roles,
    href: '/roles',
};

export const exemptionsTab: Tab = {
    name: pageTitles?.inbox,
    href: '/exemptions',
};

export const structuresTab: Tab = {
    name: pageTitles?.structures,
    href: '/structures',
};

// bd = Beneficiary Designations
export const bdClausesTab: Tab = {
    name: pageTitles?.clauses,
    href: '/clauses',
};

export const bdContractsTab: Tab = {
    name: pageTitles?.contracts,
    href: '/contracts',
};

export const bdInboxTab: Tab = {
    name: pageTitles?.inbox,
    href: '/beneficiary-designations',
};
