import { coreSharedMessages } from 'core';
import { defineMessages } from 'react-intl';

export const sharedMessages = {
    'menu.home': coreSharedMessages['menu.home'],
    'menu.documents': coreSharedMessages['menu.documents'],
    'menu.contact': coreSharedMessages['menu.contact'],
    'menu.structures': coreSharedMessages['menu.administration'],
    'menu.account': coreSharedMessages['menu.account'],
    active: coreSharedMessages.active,
    inactive: coreSharedMessages.inactive,
    ...defineMessages({
        'menu.exemptions': {
            id: 'menu.exemptions',
            defaultMessage: "Dispenses d'affiliation",
        },
        'menu.legal-documents': {
            id: 'menu.legal-documents',
            defaultMessage: 'Documentation légale',
        },
        'menu.beneficiaryDesignation': {
            id: 'menu.beneficiary-designation',
            defaultMessage: 'Désignation de bénéficiaires',
        },
        'menu.groupsAndCompanies': {
            id: 'menu.groups-and-companies',
            defaultMessage: 'Groupes et entreprises',
        },
        'menu.usersAndRoles': {
            id: 'menu.users-and-roles',
            defaultMessage: 'Utilisateurs et rôles',
        },
        'menu.users': {
            id: 'page-title.users',
            defaultMessage: 'Utilisateurs',
        },
        'menu.roles': {
            id: 'page-title.roles',
            defaultMessage: 'Rôles',
        },
        'menu.managementEnvironments': {
            id: 'menu.management-environments',
            defaultMessage: 'Environnements de gestion',
        },
        'menu.clauses': {
            id: 'menu.clauses',
            defaultMessage: 'Clauses standards',
        },
        receivedAt: {
            id: 'date.received-message-date',
            defaultMessage: 'Reçu le {date}',
        },
        mainInfos: {
            id: 'components.main-infos',
            defaultMessage: 'Informations générales',
        },
        companies: {
            id: 'components.companies',
            defaultMessage: 'Entreprises',
        },
        theme: {
            id: 'components.theme.title',
            defaultMessage: 'Thème',
        },
        services: {
            id: 'components.services.title',
            defaultMessage: 'Services',
        },
        noService: {
            id: 'components.no-service-set-up',
            defaultMessage: 'Aucun service paramétré',
        },
        linkedTo: {
            id: 'components.linked-to',
            defaultMessage:
                '{gender, select, male {Rattaché à} female {Rattachée à} other {Rattaché à} }',
        },
        contactEmployees: {
            id: 'components.legal-documents.contactEmployees',
            defaultMessage:
                '{number, plural, =0 {# salarié} one {# salarié} other {# salariés}} à contacter',
        },
        pending: {
            id: 'components.status.pending',
            defaultMessage: 'En attente',
        },
        nbCompanies: {
            id: 'components.companies-number',
            defaultMessage:
                '{number, plural, =0 {# entreprise} one {# entreprise} other {# entreprises}}',
        },
        nbGroups: {
            id: 'components.groups-number',
            defaultMessage:
                '{number, plural, =0 {# groupe} one {# groupe} other {# groupes}}',
        },
        created: {
            id: 'date.created-clause',
            defaultMessage: 'Créée le',
        },
        modified: {
            id: 'date.modified-clause',
            defaultMessage: 'Modifiée le',
        },
        signed: {
            id: 'date.signed-exemptions',
            defaultMessage: 'Signé le',
        },
        insurer: {
            id: 'components.clause-type.insurer',
            defaultMessage: 'Assureur',
        },
        company: {
            id: 'components.clause-type.company',
            defaultMessage: 'Entreprise',
        },
        group: {
            id: 'components.group.title',
            defaultMessage: 'Groupe',
        },
        'collective-agreement': {
            id: 'components.clause-type.collective-agreement',
            defaultMessage: 'Convention collective',
        },
        'sent-again': {
            id: 'date.sent-again',
            defaultMessage: 'Relancé le',
        },
        validated: {
            id: 'date.validated',
            defaultMessage: 'Validé le',
        },
        sent: {
            id: 'date.sent',
            defaultMessage: 'Envoyé le',
        },
        myDocument: {
            id: 'page-title.my-document',
            defaultMessage: 'Mon document',
        },
        attachedGroups: {
            id: 'components.links.groups',
            defaultMessage: 'Groupes rattachés',
        },
        attachedCompanies: {
            id: 'components.links.companies',
            defaultMessage: 'Entreprises rattachées',
        },
        listTitle: {
            id: 'components.mailing-list.title',
            defaultMessage: 'Liste d’envoi',
        },
        addedAt: {
            id: 'components.common.added-at',
            defaultMessage: 'Ajouté le',
        },
        addEmployeeTitle: {
            id: 'components.employees-addition-modal.title',
            defaultMessage: 'Ajout de salariés',
        },
        copyUrl: {
            id: 'exemptions.employee-portal.copy-url',
            defaultMessage: 'Copier le lien vers le portail salarié',
        },
        code: {
            id: 'exemptions.employee-portal.copy-code',
            defaultMessage: 'Code : {code}',
        },
    }),
};

export const themeMessages = defineMessages({
    themeTitle: {
        id: 'components.theme.title',
        defaultMessage: 'Thème',
    },
    color: {
        id: 'components.theme.color',
        defaultMessage: 'Couleur',
    },
    logo: {
        id: 'components.theme.logo',
        defaultMessage: 'Logo',
    },
    byDefault: {
        id: 'components-by-default',
        defaultMessage: 'Par défaut',
    },
    resetTheme: {
        id: 'buttons.revert-to-default-theme',
        defaultMessage: 'Revenir au thème par défaut',
    },
    deleteConfirmationMessage: {
        id: 'components.confirmation-modal.pe-admin.theme-default-text',
        defaultMessage:
            'Etes-vous sûr de vouloir revenir au thème par défaut ?',
    },
});

export const errors = defineMessages({});

export const weekDays = defineMessages({
    monday: {
        id: 'components.days.monday',
        defaultMessage: 'Lundi',
    },
    tuesday: {
        id: 'components.days.tuesday',
        defaultMessage: 'Mardi',
    },
    wednesday: {
        id: 'components.days.wednesday',
        defaultMessage: 'Mercredi',
    },
    thursday: {
        id: 'components.days.thursday',
        defaultMessage: 'Jeudi',
    },
    friday: {
        id: 'components.days.friday',
        defaultMessage: 'Vendredi',
    },
    saturday: {
        id: 'components.days.saturday',
        defaultMessage: 'Samedi',
    },
    sunday: {
        id: 'components.days.sunday',
        defaultMessage: 'Dimanche',
    },
});
