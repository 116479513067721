import { FormObject } from 'core';

export function autoSelectCompanyGroup(
    form: FormObject,
    groupId: string,
    groupName: string
) {
    return {
        ...form,
        widgets: form.widgets.map((widget) => {
            if (widget.id === 'groups') {
                return {
                    ...widget,
                    options: [
                        {
                            label: groupName,
                            id: groupId,
                            isDefaultValue: true,
                            isTranslatable: false,
                        },
                    ],
                };
            }
            return widget;
        }),
    };
}
