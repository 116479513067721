import { Flex, Stack } from '@chakra-ui/react';
import { HeaderPrimaryType, HeaderSecondaryType } from 'core';
import { Breadcrumbs } from 'design-system/components';
import { useWindowBreakpoints } from 'design-system/hooks';
import { CONTAINER_WIDTHS } from 'components/consts';
import { HeaderContainer } from './HeaderContainer';
import { HeaderTabs } from './HeaderTabs';
import { HeaderTitle } from './HeaderTitle';

type HeaderPrimaryProps = HeaderPrimaryType;
type HeaderSecondaryProps = HeaderSecondaryType;

function HeaderPrimary({
    isBordered = false,
    progressBar,
    tabs,
    children,
    description,
    tabsOptions,
    size = CONTAINER_WIDTHS.PRIMARY,
    ...boxProps
}: HeaderPrimaryProps) {
    return (
        <>
            <HeaderContainer
                size={size}
                isBordered={isBordered}
                progressBar={progressBar}
                {...boxProps}>
                <div>
                    <HeaderTitle>{children}</HeaderTitle>
                    {description}
                </div>
            </HeaderContainer>
            {tabs && <HeaderTabs size={size} tabs={tabs} {...tabsOptions} />}
        </>
    );
}

function HeaderSecondary({
    isBordered,
    progressBar,
    tabs,
    breadcrumbs,
    children,
    description,
    tabsOptions,
    size = CONTAINER_WIDTHS.SECONDARY,
    subtitle,
    ...boxProps
}: HeaderSecondaryProps) {
    const { isDesktop, breakpoint } = useWindowBreakpoints();
    const showBorder = isDesktop || breakpoint === 'tablet.large';

    return (
        <>
            <HeaderContainer
                size={size}
                isBordered={isBordered ?? showBorder}
                progressBar={progressBar}
                flexDir="column"
                {...boxProps}>
                <Flex
                    mb={breadcrumbs ? 4 : 0}
                    justifyContent="space-between"
                    flexDir={breadcrumbs ? 'row' : 'row-reverse'}>
                    {breadcrumbs && <Breadcrumbs {...{ breadcrumbs }} />}
                </Flex>
                <Stack spacing="3">
                    <HeaderTitle>{children}</HeaderTitle>
                    {subtitle}
                    {description}
                </Stack>
            </HeaderContainer>
            {tabs && <HeaderTabs size={size} tabs={tabs} {...tabsOptions} />}
        </>
    );
}

export { HeaderPrimary, HeaderSecondary };
