import React from 'react';
import { useIntl } from 'react-intl';
import { coreSharedMessages, errorToastsMessages } from 'core';
import { useToast } from 'design-system/components';
import DeleteConfirmationModal from 'core/components/modals/DeleteConfirmationModal';
import { useDeleteDocument } from 'lib/contracts/api/useDeleteDocument';
import { contractsPath } from 'lib/contracts';
import { useQueryClient } from '@tanstack/react-query';

type DeleteDocumentProps = {
    identifier: string;
    name: string;
    isOpen: boolean;
    onClose: () => void;
};

const intlMessages = {
    message: {
        id: 'components.confirmation-modal.pe-admin.deletion-contract-document-text',
        defaultMessage:
            'Voulez-vous réellement supprimer le document lié au contrat {number} ?',
    },
};

export default function DeleteDocument({
    identifier,
    name,
    isOpen,
    onClose,
}: DeleteDocumentProps) {
    const { formatMessage } = useIntl();
    const { mutate, isPending } = useDeleteDocument();
    const queryClient = useQueryClient();

    const successToast = useToast({
        status: 'success',
        description: formatMessage(coreSharedMessages.deletionToast),
    });

    const errorToast = useToast({
        status: 'error',
    });

    const handleDeleteConfirm = () => {
        mutate(identifier, {
            onSuccess: () => {
                successToast();
                queryClient.invalidateQueries({
                    queryKey: [contractsPath, 'GET'],
                });
            },
            onError: (error) => {
                errorToast({
                    description: formatMessage(
                        errorToastsMessages[error?.code] ||
                            coreSharedMessages.error
                    ),
                });
            },
            onSettled: () => {
                onClose();
            },
        });
    };

    return (
        <>
            {isOpen && (
                <DeleteConfirmationModal
                    {...{ isOpen, isPending, onClose }}
                    message={formatMessage(intlMessages.message, {
                        number: name,
                    })}
                    onConfirm={handleDeleteConfirm}
                />
            )}
        </>
    );
}
