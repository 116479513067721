import React from 'react';
import { Flex, Text } from '@chakra-ui/react';
import {
    defineMessage,
    FormattedMessage,
    MessageDescriptor,
    useIntl,
} from 'react-intl';

const intlMessage = defineMessage({
    id: 'components.layouts.navbar.role',
    defaultMessage: 'Menu principal',
});

function NavBarContainer({ children }: React.PropsWithChildren<unknown>) {
    const { formatMessage } = useIntl();

    return (
        <Flex
            as="nav"
            aria-label={formatMessage(intlMessage)}
            role="navigation"
            align="center"
            justify="space-between"
            w="100%"
            position="sticky"
            zIndex={11}>
            {children}
        </Flex>
    );
}

type NavBarProps = {
    title: MessageDescriptor | string;
};

function NavBar({ title }: NavBarProps) {
    return (
        <NavBarContainer>
            <Text fontWeight={'bold'} fontSize="3xl">
                {typeof title === 'string' ? (
                    title
                ) : (
                    <FormattedMessage {...title} />
                )}
            </Text>
        </NavBarContainer>
    );
}

export default NavBar;
