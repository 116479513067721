import { Button, Container, useDisclosure, VStack } from '@chakra-ui/react';
import EditButton from 'core/components/EditButton';
import DeleteConfirmationModal from 'core/components/modals/DeleteConfirmationModal';
import Tile from 'core/components/Tile';
import { PenIcon, ReplyIcon } from 'design-system/icons';
import { CompanyDetails } from 'lib/companies';
import { Group } from 'lib/groups/types';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { themeFormPath, themeMessages } from 'lib/shared';
import EditTheme from 'components/EditTheme';
import TileColor from 'core/components/TileColor';
import TileImage from 'core/components/TileImage';
import { useQueryClient } from '@tanstack/react-query';
import { coreSharedMessages, errorToastsMessages, BoxLayout } from 'core';
import { useWindowBreakpoints } from 'design-system/hooks';
import { useToast } from 'design-system/components';
import { getResourceType } from 'lib/shared/helpers';
import { useResetTheme } from 'lib/shared/api/useResetTheme';
import { DetailedEnvironment } from 'lib/environments';
import { FloatingButton } from 'components/Layouts/FloatingButton';

type ThemeProps = {
    data: Group | CompanyDetails | DetailedEnvironment;
    showEditButton: boolean;
    onEditSuccess: () => void;
};

export default function Theme({
    data,
    showEditButton,
    onEditSuccess,
}: ThemeProps) {
    const { formatMessage } = useIntl();
    const intl = useIntl();
    const queryClient = useQueryClient();
    const { isSmallDevice } = useWindowBreakpoints();
    const { mutate, isPending } = useResetTheme(data);

    const {
        isOpen: isUpdateOpen,
        onOpen: onUpdateOpen,
        onClose: onUpdateClose,
    } = useDisclosure();

    const {
        isOpen: isResetOpen,
        onOpen: onResetOpen,
        onClose: onResetClose,
    } = useDisclosure();

    const successToast = useToast({
        status: 'success',
        description: formatMessage(coreSharedMessages.confirmationToast),
    });

    const errorToast = useToast({
        status: 'error',
    });

    const handleResetThemeConfirm = () => {
        const { identifier } = data;
        mutate(identifier, {
            onSuccess: () => {
                const convertedPath = themeFormPath.replace(
                    '[resource]',
                    getResourceType(data)
                );
                queryClient.invalidateQueries({
                    queryKey: [convertedPath.replace('/theme', ''), identifier],
                });
                queryClient.invalidateQueries({
                    queryKey: [convertedPath, identifier, 'OPTIONS'],
                    refetchType: 'active',
                });
                successToast();
            },
            onError: (error) => {
                errorToast({
                    description: formatMessage(
                        errorToastsMessages[error?.code] ||
                            coreSharedMessages.error
                    ),
                });
            },
            onSettled: () => {
                onResetClose();
            },
        });
    };

    return (
        <>
            {isUpdateOpen && (
                <EditTheme
                    isOpen={isUpdateOpen}
                    resource={data}
                    onClose={onUpdateClose}
                    onSuccess={onEditSuccess}
                />
            )}
            {isResetOpen && (
                <DeleteConfirmationModal
                    {...{
                        isOpen: isResetOpen,
                        isPending,
                        onClose: onResetClose,
                    }}
                    message={formatMessage(
                        themeMessages.deleteConfirmationMessage
                    )}
                    onConfirm={handleResetThemeConfirm}
                />
            )}
            <BoxLayout bg="white">
                <Container
                    size={'full'}
                    display={'flex'}
                    justifyContent={'space-between'}>
                    {!data.theme?.logo && !data.theme?.primaryColor ? (
                        <Tile
                            label={intl.formatMessage(themeMessages.themeTitle)}
                            value={intl.formatMessage(themeMessages.byDefault)}
                        />
                    ) : (
                        <VStack alignItems={'start'} spacing={7} w={'100%'}>
                            <TileColor
                                color={data.theme.primaryColor}
                                label={intl.formatMessage(themeMessages.color)}
                            />
                            <TileImage
                                image={data.theme.logo}
                                label={intl.formatMessage(themeMessages.logo)}
                            />

                            {data.theme.logo && data.theme.primaryColor && (
                                <Button
                                    variant="ghost"
                                    color="primary.main"
                                    fontSize="md"
                                    fontWeight="bold"
                                    rightIcon={<ReplyIcon />}
                                    onClick={onResetOpen}>
                                    <FormattedMessage
                                        {...themeMessages.resetTheme}
                                    />
                                </Button>
                            )}
                        </VStack>
                    )}
                    {showEditButton &&
                        (!isSmallDevice ? (
                            <EditButton onEdit={onUpdateOpen} />
                        ) : (
                            <FloatingButton
                                Icon={PenIcon}
                                handleOnClick={onUpdateOpen}
                            />
                        ))}
                </Container>
            </BoxLayout>
        </>
    );
}
