import { Box } from '@chakra-ui/react';
import { ErrorIllustration } from 'core';
import ErrorAccordion from 'core/components/errors/ErrorAccordion';
import type { SentryErrorArgs } from 'core/components/errors/types';

export function CardError({ error }: SentryErrorArgs) {
    return (
        <Box
            rounded="md"
            p="4"
            border="1px solid"
            borderColor="strokes.medium"
            boxShadow="md">
            <ErrorIllustration
                p="5"
                sx={{
                    '> * + *': {
                        mt: '10',
                    },
                }}>
                <ErrorAccordion message={error.message} />
            </ErrorIllustration>
        </Box>
    );
}
