export function getBase64MimeType(base64: string) {
    switch (base64.charAt(0)) {
        case '/':
            return 'image/jpeg';
        case 'i':
            return 'image/png';
        case 'R':
            return 'image/gif';
        default:
            return 'image/svg+xml';
    }
}
