import axios from 'axios';
import { useQuery } from '@tanstack/react-query';
import type { FormObject, FetchQueryParams } from 'core';

type Scope = 'register/security' | 'security/public';

export async function fetchPublicForm({
    queryKey: [urn, iri],
}: FetchQueryParams) {
    try {
        if (!iri) return;

        const response = await axios.options(`${urn}${iri}`);
        return response.data.data as FormObject;
    } catch (error) {
        throw new Error(error);
    }
}

export function usePublicForm(iri: string, scope: Scope = 'register/security') {
    return useQuery<FormObject, Error>({
        queryKey: [`/api/proxy/${scope}`, iri],
        queryFn: fetchPublicForm,
    });
}
